.prestation{
  padding: 30px 0;
  &_image{
    width: 100%;
    img{
      width: 100%;
    }
  }
  &_content{
    text-align: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    &_btn{
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      gap: 1rem;
      .btn{
        width: 100%;
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: 768px){
  .prestation{
    max-width: 1280px;
    margin: 14rem auto 9rem;
    padding: 0;
    &_content{
      &_btn{
        flex-direction: row;
        width: 80%;
        margin: 0 auto;
        .btn{
          width: 30%;
          margin: 0 0 0 auto;
          &:nth-child(2){
            margin: 0 auto 0 0 ;
          }
        }
      }
    }
  }
}