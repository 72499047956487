.realisations{
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 0 30px;
  &_container{
    &_left{
      padding: 30px 15px;
      &_title{
        text-align: center;
        margin-top: 3rem;
        h2{
          font-size: 32pt;
          line-height: 32pt;
          font-weight: 700;
        }
      }
      ul{
        overflow: scroll;
        list-style-type: none;
        display: flex;
        flex-wrap: nowrap;
        gap: 3rem;
        padding: 30px 0 30px 15px;
        align-items: center;
        li{
          flex: 0 0 65%;
          text-align: center;
          p{
            color: #D9D9D9;
            font-size: 16pt;
            line-height: 16pt;
            font-weight: 700;
          }
          &.active{
            p{
              color: black;
            }
          }
        }
      }
    }
    &_right{
      position: relative;
      &_list{
        &_swiper{
          display: none !important;
          transition: .3s all ease;
          .sliderTop{
            height: 250px;
            margin-bottom: 1rem;
            img{
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          .sliderBottom{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            &_left{
              font-size: 14pt;
              font-weight: 400;
              line-height: 16pt;
              a{
                color: black;
              }
            }
          }
          &.active{
            display: block !important;
          }
        }

        .swiper-button-disabled{
          cursor: unset !important;
          opacity: 0;
          transition: .3s all ease;
        }
      }
      .sliderBottom{
        display: none;
        &_left{
          text-align: center;
          margin-bottom: 1rem;
          a{
            color: black;
          }
        }
        &.active{
          display: block;
          .navigationButton{
            position: absolute;
            width: 95%;
            display: flex;
            justify-content: space-between;
            top: 40%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 1;
            #realPrev0, #realPrev1, #realPrev2, #realNext0, #realNext1, #realNext2{
              cursor: pointer;
              &:hover{
                animation: bounce 2s ease infinite;
              }
            }
          }
        }
        .navigationButton{
          display: none;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .realisations{
    position: relative;
    padding: 0;
    &_container{
      display: flex;
      justify-content: end;
      gap: 5rem;
      margin: 10rem 0;
      &_left{
        flex: 0 0 28%;
        &_title{
          margin-bottom: 5rem;
        }
        ul{
          overflow: auto;
          flex-direction: column;
          align-items: start;
          li{
            cursor: pointer;
            text-align: left;
            font-size: 14pt;
            &:not(:last-child){
              border-bottom: 1px solid #D9D9D9;
              padding-bottom: 4rem;
            }
          }
        }
      }
      &_right{
        flex: 0 0 55%;
        width: 10%;
        height: 580px;
        &_list{
          height: 100%;
          &_swiper{
            height: 100%;
            .sliderTop{
              height: 100%;
              margin-bottom: unset;
            }
          }
          .sliderBottom{
            display: none;
            flex-direction: row;
            justify-content: end;
            gap: 0;
            align-items: stretch;
            position: relative;
            &.active{
              .navigationButton{
                position: relative;
                width: 20%;
                display: flex;
                justify-content: space-evenly;
                left: unset;
                top: unset;
                transform: unset;
              }
            }
            .navigationButton{
              width: 20%;
              display: flex;
              align-items: center;
              justify-content: space-evenly;
            }
            &_left{
              margin-bottom: unset;
              padding: 0 10px;
              width: 40%;
              height: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              //border: 1px solid black;
              a{
                color: black;
              }
            }
            &_right{
              width: 40%;
              .btn{
                width: 100%;
                a{
                  width: 100%;
                  justify-content: center;
                }
              }
            }
            &.active{
              display: flex;
            }
          }
        }
      }
    }
    //&:before{
    //  content: '';
    //  position: absolute;
    //  width: 35%;
    //  height: 20%;
    //  top: -50px;
    //  right: 0;
    //  background-color: black;
    //}
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(-5px);
  }
}