.textImage-single{
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 30px 0;
  &_text{
    &_content{
      ul, ol{
        li{
          strong{
            font-weight: 700;
            color: #000;
            font-size: 14pt;
            line-height: 22pt;
          }
          color: #000;
          font-size: 14pt;
          line-height: 22pt;
        }
      }
    }
  }
  &.leftPosition{
    flex-direction: column-reverse;
  }
  &_text{
    padding: 15px;
  }
  &_image{
    img{
      width: 100%;
      display: flex;
      height: 100%;
    }
  }
}

@media screen and (min-width: 768px){
  .textImage-single{
    flex-direction: row;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    justify-content: space-between;
    padding: 9rem 0 0;
    h2{
      font-weight: 700;
      color: #869aab;
    }
    p{
      font-size: 14pt;
      line-height: 22pt;
      i{
        font-size: 14pt;
        line-height: 22pt;
      }
    }
    &.leftPosition{
      flex-direction: row-reverse;
    }
    &_image{
      flex: 0 0 50%;
    }
    &_text{
      max-width: 600px;
    }
  }
}