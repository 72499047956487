.breadcrumb{
  padding: 0 15px;
  margin: 3rem 0 5rem;
  gap: .5rem;
  display: none;
  a{
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: italic;
    color: black;
    &:after{
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: -5px;
      left: 0;
      background-color: black;
      transform-origin: bottom left;
      transition: transform 0.25s ease-out;
    }
    &:hover{
      &:after{
        transform: scaleX(100%);
      }
    }
  }
  p{
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: italic;
    color: black;
  }
}

@media screen and (min-width: 768px) {
  .breadcrumb{
    display: flex;
    max-width: 1280px;
    margin: 10rem auto 2rem;
    padding: 0;
  }
}