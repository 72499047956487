.labels{
  padding: 30px 15px;
  &_container{
    &_title{
      text-align: center;
      margin-bottom: 3rem;
      h2{
        font-size: 32pt;
        line-height: 32pt;
        font-weight: 700;
      }
    }
    &_list{
      &.defileParent{
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        .defile{
          display: flex;
          animation: defile 8s linear infinite;
          li{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            margin: 0 5rem;
            img{
              width: 100%;
              object-fit: contain;
            }
          }
        }
        &:hover{
          .defile{
            animation-play-state: paused;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px){
  .labels{
    padding: 0 15px;
    margin: 9rem auto 0;
    max-width: 1280px;
    &_container{
      &_list{
        overflow: auto;
        justify-content: center;
      }
    }
  }
}


@keyframes defile {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}