.offreCard{
  border: 1px solid #BFBFBF;
  position: relative;
  &_content{
    padding: 2rem 3rem;
    color: black;
    &_top{
      border-bottom: 1px solid #BFBFBF;
      padding-bottom: 5rem;
      &_tags{
        margin-bottom: 2rem;
        p{
          font-style: italic;
          font-size: 1.6rem;
          border-bottom: 13px solid #F0F0F0;
          width: fit-content;
          line-height: 13px;
        }
      }
      &_poste{
        p{
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
    &_bottom{
      padding-top: 5rem;
      p{
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
  .hover{
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: .3s all ease;
    background-color: rgba(240, 240, 240, .5);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    p{
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  &:hover{
    .hover{
      transition: .3s all ease;
      opacity: 1;
    }
  }
}