.textImage{
  margin: 5rem 0;
  &_list{
    display: flex;
    flex-direction: column;
    gap: 5rem;
    &_item{
      &_left{
        width: 100%;
        img{
          width: 100%;
        }
      }
      &_right{
        padding: 15px;
        &_text{
          h3{
            font-size: 25pt;
            font-family: 'Cairo', sans-serif;
            color: $grey;
            line-height: 35pt;
            a{
              font-size: 25pt;
              font-family: 'Cairo', sans-serif;
              color: $grey;
              line-height: 35pt;
              text-decoration: unset;
              strong{
                font-weight: 700;
                font-size: 25pt;
                font-family: 'Cairo', sans-serif;
                color: $grey;
                a{
                  font-weight: 700 !important;
                }
              }
            }
            strong{
              font-weight: 700;
              font-size: 25pt;
              font-family: 'Cairo', sans-serif;
              color: $grey;
              a{
                font-weight: 700;
              }
            }
          }
          p, a{
            color: black;
            font-size: 16pt;
            line-height: 22pt;
            strong{
              font-weight: 700;
              color: black;
              font-size: 16pt;
              line-height: 22pt;
            }
          }
          a{
            text-decoration: underline;
          }
        }
        .btn{
          margin-top: 3rem;
          width: 100%;
          a{
            width: 100%;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px){
  .textImage{
    max-width: 1280px;
    margin: 9rem auto;
    &_list{
      gap: 10rem;
      &_item{
        align-items: stretch;
        display: flex;
        justify-content: space-between;
        &_left{
          flex: 0 0 48%;
          opacity: 0;
          transform: translateX(-50px);
          transition: .3s all ease;
          img{
            height: 100%;
            object-fit: cover;
          }
        }
        &_right{
          height: fit-content;
          flex: 0 0 46%;
          opacity: 0;
          transform: translateX(50px);
          transition: .3s all ease;
          &_text{
            h3{
              font-size: 27pt;
              strong{
                font-size: 27pt;
              }
            }
          }
          .btn{
            width: fit-content;
            margin: 3rem 0 0;
          }
        }
        &:nth-child(2n){
          flex-direction: row-reverse;
          .textImage_list_item_left{
            transform: translateX(50px);
          }
          .textImage_list_item_right{
            transform: translateX(-50px);
          }
        }
        &.revealOn{

          .textImage_list_item_left{
            flex: 0 0 48%;
            opacity: 1;
            transform: translateX(0);
            transition: .5s all ease;
          }
          .textImage_list_item_right{
            opacity: 1;
            transform: translateX(0);
            transition: .5s all ease;
          }
        }
      }
    }
  }
}