.articleCard{
  background-color: #F7F7F7;
  &_image{
    height: 150px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &_content{
    padding: 20px;
    display: flex;
    flex-direction: column;
    &_top {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 3rem;
      &_etiquette {
        p {
          font-size: 14px;
          font-weight: 400;
          font-style: italic;
        }
      }

      &_title {
        h3 {
          font-size: 24px;
          font-weight: 700;
        }
      }

      &_description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        margin-bottom: 2rem;
      }
    }
    &_btn{
      .btn{
        width: 100%;
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .articleCard{
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    &_image{
      //flex: 0 0 40%;
    }
    &_content{
      flex-grow: 1;
      justify-content: space-between;
      min-height: 250px;
      &_top{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        &_description{
          margin-bottom: 0;
        }
      }
    }
  }
}