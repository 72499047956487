.planSite{
  &_container{
    h2{
      margin-bottom: 5rem;
      text-align: center;
    }
    ul{
      margin-left: 2%;
      list-style-type: revert;
      a{
        text-decoration: unset;
        color: black;
        font-size: 16pt;
        line-height: 22pt;
        &:hover{
          font-weight: 700;
        }
      }
    }
  }
}

@media screen and (min-width: 768px){
  .planSite{
    &_container{
      max-width: 1280px;
      margin: 9rem auto;
    }
  }
}