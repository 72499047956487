.blog{
  padding: 15px;
  &_content{
    margin: 5rem 0;
    &_left {
      &_indiv {
        &_title {
          margin-bottom: 3rem;
        }

        &_list {
          margin-bottom: 3rem;
        }

        &_bottom {
          display: flex;
          flex-direction: row;
          gap: 2rem;
          justify-content: end;
          margin-top: 2rem;

          .navigationButton {
            cursor: pointer;
            display: flex;
            gap: 2rem;
            align-items: center;
          }
        }
      }
    }
    &_right{
      display: none;
    }
  }
}

@media screen and (min-width: 768px){
  .blog{
    max-width: 1280px;
    margin: 0 auto;
    &_content{
      margin: 5rem 0 10rem;
      display: flex;
      position: relative;
      justify-content: space-between;
      &_left {
        display: flex;
        flex-direction: column;
        width: 75%;
      }
      &_right{
        display: block;
        position: sticky;
        top: 10px;
        width: 20%;
        height: fit-content;
        &_list{
          &_indiv{
            a {
              color: black;
              text-decoration: none;
              h2 {
                text-align: right;
                font-size: 16px;
                font-style: italic;

                strong {
                  text-align: right;
                  font-size: 16px;
                  font-style: italic;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1070px){
  .blog{
    &_content{
      &_right{
        display: block;
        position: sticky;
        top: 100px;
        width: 20%;
        height: fit-content;
      }
    }
  }
}