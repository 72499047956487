.recrutement{
  &_title{
    padding: 0 14px;
  }
  &_chapeau{
    margin: 5rem 0;
    padding: 0 14px;
    text-align: center;
  }
  &_offres{
    padding: 0 14px;
    margin: 5rem 0;
    h2{
      margin-bottom: 3rem;
    }
    &_list{
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  }
  &_spontanee{
    position: relative;
    padding: 3rem 14px;
    //.pattern{
    //  pointer-events: none;
    //  position: absolute;
    //  inset: 0;
    //  width: 100%;
    //  height: 100%;
    //  object-fit: cover;
    //}
    &_container {
      &_title {
        margin: 3rem 0;
      }

      &_content {
        &_description {
          margin-bottom: 3rem;
        }
      }
    }
  }
}

@media screen and (min-width: 768px){
  .recrutement{
    &_title{
      max-width: 1280px;
      margin: 0 auto;
    }
    &_chapeau{
      max-width: 1280px;
      margin: 10rem auto;
      p{
        font-size: 32px;
        line-height: 35px;
        strong{
          font-size: 32px;
          line-height: 35px;
        }
      }
    }
    &_offres{
      max-width: 1280px;
      margin: 5rem auto;
      &_list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 15px;
      }
    }
    &_spontanee{
      &_container{
        max-width: 1280px;
        margin: 0 auto;
        &_content{
          max-width: 640px;
          margin: 0 auto;
          &_description{
            p{
              font-size: 24px;
              line-height: 30px;
            }
          }
          &_btn{
            .btn{
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}