.btn{
  width: max-content;
  text-align: center;
  margin: 0 auto;
  display: flex;
  background-color: black;
  border: 1px solid black;
  a{
    padding: 15px 25px;
    color: white;
    width: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    p{
      cursor: pointer;
      color: white;
    }
    &:after{
      display: none;
    }

  }
  &.transparent{
    background: transparent;
    border: 1px solid white;
    p{
      color: white;
    }
  }
  &:hover{
    background-color: white;
    border: 1px solid black;
    a{
      p{
        color: black;
      }
    }
  }
  &.whiteColor{
    background-color: white;
    a{
      p{
        color: black;
      }
    }
    &:hover{
      background-color: black;
      border: 1px solid white;
      a{
        p{
          color: white;
        }
      }
    }
  }
}