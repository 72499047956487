.article{
  &_title{
    text-align: center;
    margin-top: 3rem;
    padding: 0 15px;
  }
  &_container{
    padding: 0 15px;
    margin-top: 3rem;
    &_subtitle{
      padding: 0 15px;
      p.date{
        margin-bottom: 1rem;
      }
    }
  }
  .text{
    text-align: left;
  }
  &_more{
    padding: 0 15px;
    margin-top: 3rem;
    margin-bottom: 5rem;
    &_title{
      margin-bottom: 3rem;
    }
    &_list{
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  }
}

@media screen and (min-width: 768px){
  .article{
    max-width: 1280px;
    margin: 0 auto;
    &_title{
      margin: 3rem auto 10rem;
    }
    &_container{
      max-width: 850px;
      margin: 0 auto;
      &_subtitle{
        margin-bottom: 5rem;
        padding: 0;
      }
    }
    .text{
      margin: 0;
    }
    &_more{
      &_list{
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}