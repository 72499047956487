.faq-container{
  max-width: 1280px;
  margin: 5% auto 0;
  padding: 0 40px;
  .title{
    margin: 5% 0;
    text-align: center;
    h2{
      font-weight: bold;
      font-family: 'Poppins', sans-serif;
      text-align: center;
      color : black;
    }
  }
  .list{
    padding: 10px 20px;
    margin-bottom: 10%;
    border: 1px solid #B0B0B0;
    ul{
      padding: 0;
      list-style-type: none;
      li{
        font-size: 1rem;
        border-bottom: 1px solid #DADADA;
        margin: 3.2rem 0 1.2rem 0;
        cursor: pointer;
        h2{
          text-align: center;
          position: relative;
          font-size: 19px;
          font-family: 'Poppins', sans-serif;
          color: black;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          line-height: 1.21;
          padding-right: 20px;
          font-weight: 700;
        }
        h2:before{
          transition: all 0.3s;
          background-color: black;
          content: "";
          display: block;
          height: 15px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 5px;
        }
        h2:after{
          transform: translateY(-50%) rotate(90deg);
          background-color: black;
          content: "";
          display: block;
          height: 15px;
          position: absolute;
          right: 0;
          top: 50%;
          width: 5px;
        }
        h2.open:before{
          background-color: black;
          z-index: 99;
          transform: translateY(-50%) rotate(90deg);
          transition: all 0.3s;
        }
        span.reponse{
          display: block;
          max-height: 0;
          min-height: 0px;
          font-weight: 200;
          margin-bottom: 1.2rem;
          overflow: hidden;
          transition: all 0.3s;
          padding: 0 0 0 0;
          font-family: 'Poppins', sans-serif;
          font-size: 15px;
          color: black;
          ul{
            list-style-type: none;
            li{
              border-bottom: unset;
              font-size: 1.6rem;
              line-height: 1.9rem;
              font-family: "Inter", sans-serif;
              text-decoration: unset;
              strong{
                font-size: 1.6rem;
                line-height: 1.9rem;
                font-family: "Inter", sans-serif;
                text-decoration: unset;
                font-weight: 700;
              }
              &:before{
                content: "•"; /* Insert content that looks like bullets */
                padding-right: 8px;
                color: black; /* Or a color you prefer */
              }
            }
          }
          p{
            u{
              font-size: 1.6rem;
              line-height: 1.9rem;
              font-family: "Inter", sans-serif;
              text-decoration: unset;
              text-decoration: underline;
            }
            a{
              font-size: 1.6rem;
              line-height: 1.9rem;
              font-family: "Inter", sans-serif;
              text-decoration: unset;
              color: black;
              u{
                font-size: 1.6rem;
                line-height: 1.9rem;
                font-family: "Inter", sans-serif;
                text-decoration: unset;
                text-decoration: underline;
              }
              strong{
                font-weight: 700;
              }
            }
          }
          a{
            font-size: 1.6rem;
            line-height: 1.9rem;
            font-family: "Inter", sans-serif;
            text-decoration: unset;
            color: black;
            u{
              font-size: 1.6rem;
              line-height: 1.9rem;
              font-family: "Inter", sans-serif;
              text-decoration: unset;
              text-decoration: underline;
            }
            strong{
              font-weight: 700;
            }
          }
        }
        h2.open + span.reponse{
          max-height: 1000px;
          transition: all 0.3s;
          padding: 1.2rem 0 0 0;
        }
      }
      li:last-child{
        border: none;
      }
      p{
        text-align: center;
        font-weight: normal;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .faq-container{
    width: 100%;
    margin: unset;
    padding: 30px 15px;
  }
}