.joinUs{
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 40px 15px;
  &_description{
    h2{
      color: #889AAB;
    }
  }
  &_btn{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .btn{
      width: 100%;
      justify-content: center;
    }
  }
}

@media screen and (min-width: 768px) {
  .joinUs{
    flex-direction: row;
    padding: 0 14px;
    max-width: 1280px;
    margin: 9rem auto;
    justify-content: center;
    gap: 9rem;
    &_description{
      width: 390px;
    }
    &_btn{
      flex: 0 0 30%;
      justify-content: center;
    }
  }
}