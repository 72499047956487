.realisationPage-next-previous{
  max-width: 1280px;
  margin: 5rem auto;
  display: flex;
  justify-content: space-between;
  padding: 0 14px;
  .next, .previous{
    a{
      display: flex;
      gap: 2rem;
      span{
        font-size: 1.6rem;
        line-height: 1.9rem;
        font-family: "Inter", sans-serif;
        color: black;
      }

      &:after{
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: -5px;
        left: 0;
        background-color: black;
        transform-origin: bottom left;
        transition: transform 0.25s ease-out;
      }
      &:hover{
        &:after{
          transform: scaleX(100%);
        }
      }
    }
  }
}