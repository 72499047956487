.footer{
  background-color: black;
  text-align: center;
  &_top{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 30px 15px;
    &_left{
      &_logo{
        text-align: center;
      }
      &_description{
        color: $grey;
      }
    }
    &_center{
      &_title{
        h3{
          font-size: 1.6rem;
          color: $grey;
          margin-bottom: 1rem;
        }
      }
      &_list{
        display: flex;
        flex-direction: column;
        a,p{
          color: white;
          font-size: 1.3rem;
        }
        ul{
          padding-left: 2rem;
        }
      }
    }
    &_right{
      display: flex;
      flex-direction: column;
      gap: 3rem;
      &_aPropos{
        &_title{
          h3{
            font-size: 1.6rem;
            color: $grey;
            margin-bottom: 1rem;
          }
        }
        &_list{
          display: flex;
          flex-direction: column;
          a,p{
            color: white;
            font-size: 1.3rem;
          }
        }
      }
      &_info{
        color: white;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 260px;
        margin: 0 auto;
        &_adresse{
          display: flex;
          gap: 1rem;
          &_info{
            text-align: left;
          }
        }
        &_tel{
          display: flex;
          gap: 1rem;
          align-items: center;
          &_info{
            text-align: left;
            a{
              color: white;
            }
          }
        }
      }
    }
  }
  &_bottom{
    padding: 30px 15px;
    border-top: 1px solid white;
    &_container{
      p{
        color: white;
        text-align: center;
        margin-bottom: 1rem;
      }
      a{
        color: white !important;
        text-decoration: none;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .footer{
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    &_top{
      padding: 60px 80px;
      flex-direction: row;
      justify-content: space-between;
      &_left,&_center,&_right{
        flex: 0 0 30%;
      }
      &_left{
        max-width: 390px;
        &_logo{
          margin-bottom: 3rem;
          text-align: left;
          height: 130px;
          img{
            height: 100%;
          }
        }
        &_description{
          text-align: left;
          p{
            font-size: 13pt;
          }
        }
      }
      &_center{
        max-width: 280px;
        text-align: left;
        &_title{
          h3{
            font-size: 16pt;
            margin-bottom: 3rem;
          }
        }
        &_list{
          gap: 2rem;
          a,p{
            width: fit-content;
            font-size: 13pt;
          }
          ul{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            li{
              &::before{
                color: white;
                content: "•";
                padding-right: 8px;
              }
            }
          }
        }
      }
      &_right{
        max-width: 300px;
        justify-content: space-between;
        gap: unset;
        &_aPropos{
          &_title{
            h3{
              text-align: left;
              font-size: 16pt;
              margin-bottom: 3rem;
            }
          }
          &_list{
            text-align: left;
            gap: 2rem;
            a,p{
              width: fit-content;
              font-size: 13pt;
            }
          }
        }
        &_info{
          max-width: unset;
          margin: 0;
          gap: 3rem;
          &_adresse, &_tel{
            p,a{
              font-size: 13pt;
              text-decoration: none;
              color: white;
            }
          }
          &_adresse{
            &_info{
              display: flex;
              flex-direction: column;
              gap: 1rem;
            }
          }
        }
      }
    }
    &_bottom{
      padding: 60px 80px;
    }
    &:before{
      box-shadow: 0 40vw 0 40vw black;
      -webkit-clip-path: inset(0 -40vw 0 -40vw);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
      pointer-events: none;
    }
  }
}