.pageSimple-content{
  width: 90%;
  margin: 5rem auto;
  &-header{
    margin-bottom: 5rem;
    h1{
      text-align: center;
      color: black;
    }
  }
  .list_pageSimple{
    margin-top: 10rem;
    &_content{
      margin-bottom: 5rem;
      h2{
        line-height: 3.5rem;
        font-size: 3rem;
        font-weight: 500;
        margin-bottom: 2rem;
        color: black;
      }
      p{
        font-size: 1.5rem;
        color: black;
      }
    }
  }
}
@media screen and (min-width: 768px){
  .pageSimple-content{
    width: 80%;
    margin: 15rem auto 0;
    padding-bottom: 5rem;
    .list_pageSimple{
      width: 80%;
      margin: 10% auto 10%;
    }
  }
}