.heroBanner{
  &_image{
    height: auto;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &_text{
      padding: 15px;
      margin-top: -5px;
      background-color: black;
      h1{
        color: white;
        font-family: Cairo, sans-serif;
        strong{
          font-family: Cairo, sans-serif;
        }
      }
    }
  }
  &_chapeau{
    padding: 15px;
    h2,p{
      strong{
        font-size: 2rem;
      }
      font-size: 2rem;
      text-align: center;
      color: $grey;
      font-family: 'Cairo', sans-serif;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      div{
        font-size: 2rem;
        line-height: 2rem;
        text-align: center;
        color: #869AAB;
        font-family: "Cairo", sans-serif;
      }
    }
  }
}

@media screen and (min-width: 768px){
  .heroBanner{
    &_image{
      height: 70vh;
      //height: 600px;
      img{
        height: 100%;
        object-fit: cover;
      }
      &_text{
        position: absolute;
        bottom: -10%;
        max-width: 650px;
        padding: 50px 115px 50px 115px;
        z-index: 1;
      }
    }
    &_chapeau{
      max-width: 900px;
      margin: 0 auto;
      h2,p{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        div{
          font-size: 24pt;
          line-height: 35pt;
          text-align: center;
          color: #869AAB;
          font-family: "Cairo", sans-serif;
        }
        font-size: 24pt;
        line-height: 35pt;
      }
    }
  }
}