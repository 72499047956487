.contact{
  &_title{
    padding: 14px;
  }
  &_info{
    position: relative;
    padding: 5rem 14px;
    //.pattern{
    //  pointer-events: none;
    //  position: absolute;
    //  inset: 0;
    //  width: 100%;
    //  height: 100%;
    //  object-fit: cover;
    //}
    &_title{
      margin-bottom: 5rem;
    }
    &_container{
      &_left{
        margin-bottom: 5rem;
        ul{
          list-style-type: none;
          display: flex;
          flex-direction: column;
          gap: 3rem;
          li{
            &:not(:last-child){
              border-bottom: 1px solid #D9D9D9;
              padding-bottom: 3rem;
            }
            p{
              &.boldParagraph{
                font-weight: 700;
                margin-bottom: 1rem;
              }
            }
            a{
              text-decoration: none;
              color: black;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px){
  .contact{
    &_title{
      max-width: 1280px;
      margin: 0 auto;
    }
    &_info{
      &_title{
        max-width: 1280px;
        margin: 0 auto 5rem;
      }
      &_container{
        justify-content: center;
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
        gap: 10rem;
        &_right{
          flex: 0 0 31%;
        }
      }
    }
  }
}