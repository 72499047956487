@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$grey: #869AAB;
$secondary: #C2A24D;

*, html, body{
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body{
  overflow-x: hidden;
}

h1{
  font-size: 3.2rem;
  line-height: 3.5rem;
  font-family: 'Inter', sans-serif;
  strong{
    font-size: 3.2rem;
    line-height: 3.5rem;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }
}

h2{
  font-size: 3.2rem;
  line-height: 3.5rem;
  font-family: 'Cairo', sans-serif;
  font-weight: 700;
  span, strong{
    font-size: 3.2rem;
    line-height: 3.5rem;
    font-family: 'Cairo', sans-serif;
    font-weight: 700;
  }
}

h3{
  font-size: 2.8rem;
  line-height: 3.5rem;
  font-family: 'Cairo', sans-serif;
  span, strong{
    font-size: 2.8rem;
    line-height: 3.5rem;
    font-family: 'Cairo', sans-serif;
    font-weight: 700;
  }
}
h4{
  font-size: 14pt;
  line-height: 22pt;
  font-style: italic;
}
p, a{
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: 'Inter', sans-serif;
  text-decoration: unset;
  strong{
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-family: "Inter", sans-serif;
    text-decoration: unset;
    font-weight: 700;
  }
  i{
    font-style: italic;
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-family: 'Inter', sans-serif;
    text-decoration: unset;
  }
  a{
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-family: 'Inter', sans-serif;
    //text-decoration: underline;
    color: #869AAB;
    transition: .3s all ease;
    &:hover{
      color: black;
      transition: .3s all ease;
    }
  }
}
a{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }
  &:hover{
    &:after{
      transform: scaleX(100%);
    }
  }
  &.white{
    &:after{
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: -5px;
      left: 0;
      background-color: black;
      transform-origin: bottom left;
      transition: transform 0.25s ease-out;
    }
    &:hover{
      &:after{
        transform: scaleX(100%);
      }
    }
  }
}

.honung {
  display: none;
}