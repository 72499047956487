.formulaire {
  padding: 0 14px;
  &_title{
    h2{
      font-weight: 700;
      text-align: center;
      margin: 5rem 0;
    }
  }
  form {
    #dynamic_formContact1 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      ul{
        list-style-type: none;
        li{
          text-align: left;
          color: red;
          font-size: 1.3rem;
          margin-bottom: 5px;
        }
      }
      input, textarea {
        -webkit-appearance: none;
        background: transparent;
        border: 1px solid #7F8080;
        color: #504F4F;
        font-size: 1.6rem;
        outline: none;
        padding: 1.5rem 0 1.5rem 2rem;
        width: 100%;
        margin-bottom: 1rem;

        &::placeholder {
          color: #504F4F;
          font-size: 1.6rem;
        }
      }
      .choice{
        text-align: left;
        margin-bottom: 4%;
        input[type=checkbox]{
          width: 20px;
          height: 20px;
          padding: 0;
          appearance: none;
          -webkit-appearance: none;
          background: transparent;
          border: 1px solid black;
          border-radius: 50%;
          cursor: pointer;
          margin-right: 3%;
          outline: none;
          vertical-align: middle;
          &:checked{
            background-color: black;
          }
        }
        label{
          display: block;
          padding-left: 32px;
          margin-top: -8%;
          a{
            text-decoration: underline;
            line-height: inherit;
            font-size: 1.6rem;
            color: #504F4F;
          }
          font-size: 1.2rem;
          color: #504F4F;
        }
      }

      .width-half {
        display: block;
        width: 48%;
        //display: inline-flex;
        text-align: center;
        margin-bottom: 1rem;
      }

      .width-full {
        width: 100%;
        text-align: center;
      }

      button {
        -webkit-appearance: none;
        border: 1px solid black;
        background-color: black;
        color: white;
        font-size: 2rem;
        padding: 10px 60px;
        cursor: pointer;

        &:hover {
          background: white;
          color: black;
        }
      }
      .g-recaptcha{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 5%;
      }
    }
  }
  &_iframe{
    overflow: hidden;
    iframe{
      border: 0;
      overflow: hidden;
      .form-wrapper-component .form-content-component{
        box-shadow: unset;
      }
      button{
        background: black;
      }
    }
  }
}

@media screen and (min-width: 768px){
  .formulaire{
    max-width: 700px;
    margin: 0 auto 5rem;
    form {
      #dynamic_formContact1 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(7, 0fr);
        grid-column-gap: 45px;
        grid-row-gap: 20px;
        .choice{
          label{
            margin-top: -3%;
          }
        }
        .width-full {
          &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;
          }

          &:nth-child(2) {
            grid-area: 2 / 1 / 3 / 2;
          }

          &:nth-child(3) {
            grid-area: 3 / 1 / 4 / 2;
          }

          &:nth-child(4) {
            grid-area: 4 / 1 / 5 / 2;
          }

          &:nth-child(5) {
            grid-area: 1 / 2 / 4 / 3;
          }
          &:nth-child(6) {
            grid-area: 4 / 2 / 5 / 3;
          }
          &:nth-child(7) {
            grid-area: 5 / 1 / 6 / 3;
          }
          &:last-child {
            grid-area: 7 / 1 / 7 / 3;
            button{
              height: 100%;
            }
          }

          textarea, input{
            height: 100%;
            margin-bottom: unset;
          }
        }
        .g-recaptcha {
          grid-area: 6 / 1 / 6 / 3;
        }
      }
    }
  }
}