.savoirFaire{
  padding: 30px 0;
  &_header{
    padding: 0 14px;
    h2{
      color: #889AAB;
    }
  }
  &_container{
    &_list{
      overflow: scroll;
      list-style-type: none;
      display: flex;
      flex-wrap: nowrap;
      gap: 3rem;
      padding: 30px 15px;
      align-items: center;
      li{
        //flex: 0 0 40%;
        flex: 0 0 50%;
        text-align: center;
        &.active{
          p{
            color: black;
            font-weight: 700;
          }
        }
        p{
          cursor: pointer;
          color: #D9D9D9;
        }
      }
    }
    &_text{
      position: relative;
      background-color: #F0F0F0;
      //padding: 3rem 2rem;
      padding: 3rem 2rem 3rem 4rem;
      &_indiv{
        display: none;
        ul{
          li{
            font-size: 1.6rem;
            line-height: 1.9rem;
            font-family: "Inter", sans-serif;
            text-decoration: unset;
          }
        }
        &.active{
          display: block;
        }
      }
      &:before{
        box-shadow: 0 40vw 0 40vw #F0F0F0;
        -webkit-clip-path: inset(0 -40vw 0 0);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
      }
    }
  }
  &_conclusion{
    text-align: center;
    max-width: 900px;
    margin: 3rem auto 0;
  }
}

@media screen and (min-width: 768px) {
  .savoirFaire{
    max-width: 1280px;
    margin: 0rem auto;
    &_header{
      margin: 0 auto;
      text-align: center;
      max-width: 730px;
      p{
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
    }
    &_container{
      display: flex;
      gap: 5rem;
      margin: 5rem 0;
      justify-content: center;
      &_header{
        text-align: right;
      }
      &_list{
        flex: 0 0 28%;
        flex-direction: column;
        overflow: unset;
        gap: 3rem;
        justify-content: center;
        align-items: start;
        li{
          display: flex;
          align-items: center;
          flex: unset;
          width: 100%;
          p{

            font-size: 24px;
            line-height: 30px;
          }
          &:not(:last-child){
            border-bottom: 1px solid #D9D9D9;
            padding-bottom: 4rem;
          }
        }
      }
      &_text{
        flex: 0 0 55%;
        padding: 10rem 0rem 10rem 5rem;
        &_indiv{
          p{
            font-size: 14pt;
            line-height: 22pt;
          }
          ul{
            li{
              font-size: 14pt;
              line-height: 22pt;
              margin-bottom: .5rem;
            }
          }
        }
      }
    }
    &_conclusion{
      margin: 0 auto;
    }
  }
}