.nosMateriaux{
  padding: 30px 0;
  &_image{
    display: none;
    img{
      width: 100%;
      display: flex;
    }
  }
  &_container{
    background-color: #889AAB;
    padding: 5rem 14px;
    &_header{
      margin-bottom: 3rem;
      &_title{
        margin-bottom: 2rem;
        text-align: center;
      }
      &_subtitle{
        text-align: center;
      }
    }
    &_list{
      display: flex;
      flex-direction: column;
      gap: 3rem;
      &_indiv{
        background-color: white;
        position: relative;
        &_image{
          height: 250px;
          img{
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }
        &_content{
          height: 80%;
          padding: 20px 20px 20px 40px;
          text-align: center;
          h2,h3{
            margin-bottom: 3rem;
          }
          ul{
            list-style-type: none;
            li{
              font-size: 1.6rem;
              line-height: 1.9rem;
              font-family: "Inter", sans-serif;
              text-decoration: unset;
              position: relative;
              &:not(:nth-last-child(1)){
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                &:after{
                  content: "";
                  width: 30%;
                  height: 1px;
                  position: absolute;
                  color: #869AAB;
                  background-color: #869AAB;
                  bottom: 0;
                  left: 50%;
                  transform: translateX(-50%);
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px){
  .nosMateriaux{
    max-width: 1280px;
    margin: 0 auto;
    padding: 30px 0 0;
    &_image{
      width: 50%;
      margin: 0 0 0 auto;
      position: relative;
      z-index: 2;
    }
    &_container{
      position: relative;
      padding: 10rem;
      //margin-top: -5%;
      &_header{
        &_title{
          //text-align: left;
          margin-bottom: 5rem;
        }
        &_subtitle{
          width: 80%;
          margin: 0 auto;
        }
      }
      &_list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 40px;
        grid-row-gap: 40px;
        &_indiv{
          &_image{
            height: 200px;
          }
          &_content{
            text-align: center;
            height: 70%;
            padding: 40px 35px 40px 35px;
            h2, h3{
              margin-bottom: 3rem;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      &::before{
        pointer-events: none;
        box-shadow: 0 40vw 0 40vw #889AAB;
        -webkit-clip-path: inset(0 -40vw 0 -40vw);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
      }
    }
  }
}