.imageBlock{
  padding: 30px 0;
  img{
    width: 100%;
  }
  &.halfLeftRightPosition{
    display: flex;
    height: 400px;
    picture{
      flex: 0 0 50%;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (min-width: 768px){
  .imageBlock{
    max-width: 1280px;
    margin: 0 auto;
    &.leftHalfPosition{
      width: 100%;
    }
    &.rightHalfPosition{
      width: 100%;
      margin: 0 0 0 auto;
    }
  }
}