.takeAppointment{
  position: relative;
  margin: 5% 0 0;
  img{
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &_content{
    padding-top: 3%;
    text-align: center;
    background-color: black;
    padding-bottom: 10%;
    &_title{
      margin-bottom: 1rem;
      h2{
        color: white;
      }
    }
    &_subtitle{
      margin-bottom: 3rem;
      p{
        color: white;
      }
    }
  }
}

@media screen and (min-width: 768px){
  .takeAppointment{
    margin: 5% 0 0;
    position: relative;
    height: 500px;
    &_content{
      position: absolute;
      inset: 0;
      width: 50%;
      height: fit-content;
      padding: 60px 100px;
      text-align: left;
      background-color: black;
      max-width: 700px;
      &_subtitle{
        display: flex;
        flex-direction: column;
        gap: .1rem;
      }
      .btn{
        margin: 0;
      }
    }
  }
}
@media screen and (min-width: 1980px){
  .takeAppointment{
    height: 800px;
  }
}