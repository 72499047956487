.chiffreCles{
  background-color: $grey;
  padding: 30px 15px;
  &_list{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    &_item{
      text-align: center;
      p{
        color: white;
        font-family: 'Cairo', sans-serif;
        &.chiffre{
          font-size: 60pt;
          line-height: 50pt;
          font-weight: 700;
        }
        &.sousChiffre{
          font-size: 24pt;
          line-height: 18pt;
          font-weight: 700;
          margin-bottom: 3rem;
        }
        &.descriptionChiffre{
          font-size: 21pt;
          line-height: 21pt;
          font-weight: 400;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .chiffreCles{
    margin: 9rem 0 0;
    &_list{
      flex-direction: row;
      justify-content: space-evenly;
      max-width: 1280px;
      margin: 6rem auto;
      &_item{
        flex: 0 0 17%;
      }
    }
  }
}