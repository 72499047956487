.text, .textEncadre{
  max-width: 1280px;
  margin: 3rem auto;
  padding: 40px 15px;
  //text-align: center;
  h2{
    margin-bottom: 3rem;
  }
  p, a, li{
    color: #000;
    font-size: 14pt;
    line-height: 22pt;
    strong, a{
      font-size: 14pt;
      line-height: 22pt;
    }
  }
  a{
    text-decoration: underline;
  }
  a:hover{
    color: #869aab;
  }
}

.textEncadre{
  &_container{
    padding: 30px 25px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 5px 5px #d9d9d9;
    &.half{
      width: 50%;
      margin: 0 auto;
    }
    &.fitContent{
      width: fit-content;
    }
    &.white{
      background-color: white;
    }
    &.black{
      background-color: black;
      h2, p, h3, h4, h5, li, a{
        color: white;
      }
      a{
        &:hover{
          &::after{
            content: unset;
          }
        }
      }
    }
    &.grey{
      background-color: #F0F0F0;
      a{
        &:hover{
          &::after{
            content: unset;
          }
        }
      }
    }

    &.center{
      margin: 0 auto;
    }
    &.left{
      margin: 0 auto 0 0;
    }
    &.right{
      margin: 0 0 0 auto;
    }
  }
}

@media screen and (min-width: 768px){
  .text, .textEncadre{
    margin: 0 auto;
    padding: 9rem 15px 0;
    //max-width: 1280px;

    //max-width: 900px;
    //margin: 0 auto;
  }
}