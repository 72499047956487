.unProjet{
  background: #F0F0F0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
  &_text{
    p{
      font-size: 16px;
      &.projet{
        font-weight: 700;
      }
    }
  }
  &_btn{
    .btn{
      width: 100%;
      justify-content: center;
    }
  }
}