.etapeConstruction{
  padding: 30px 15px;
  &_title{
    text-align: center;
    margin-bottom: 3rem;
    h2{
      font-size: 32pt;
      line-height: 32pt;
      font-weight: 700;
    }
  }
  &_container{
    margin-bottom: 3rem;
    &_list{
      padding: 0 30px !important;
      &_item{
        text-align: center;
        border: 1px solid #D9D9D9;
        padding: 30px 25px;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        cursor: auto;
        &_title{
          h2{
            font-weight: 700;
          }
        }
        &_description{
          margin-bottom: 3rem;
          p{
            font-size: 13pt;
            line-height: 15pt;
          }
        }
      }
    }
    .navigationButton{
      z-index: 1;
      position: absolute;
      inset: 0;
      display: flex;
      justify-content: space-between;
      top: 50%;
      transform: translateY(-50%);
      height: fit-content;
    }
  }
  &_bottom{
    //background-color: #F7F7F7;
    padding: 25px 15px;
    pointer-events: none;
    &_accompagnement, &_needHelp{
      text-align: center;
    }
    &_accompagnement{
      margin-bottom: 3rem;
    }
  }
}

@media screen and (min-width: 768px){
  .etapeConstruction{
    padding: 0 15px;
    max-width: 1280px;
    margin: 14rem auto 9rem;
    &_title{
      margin-bottom: 5rem;
    }
    &_container{
      cursor: pointer;
      max-width: 1280px;
      margin: 0 auto;
      &_list{
        .swiper-slide{
          height: auto;
        }
      }
    }
    &_bottom{
      padding: 70px 30px;
      width: 80%;
      margin: 0 auto;
      position: relative;
    }
  }
}