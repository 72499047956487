.avis{
  padding: 30px 15px;
  &_title{
    text-align: center;
    margin-bottom: 3rem;
    h2{
      font-size: 32pt;
      line-height: 32pt;
      font-weight: 700;
    }
  }
  &_subtitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
    gap: 1rem;
    span{
      font-size: 1.6rem;
      line-height: 1.9rem;
      font-family: "Inter", sans-serif;
      text-decoration: unset;
    }
    .stars{
      display: flex;
      gap: .5rem;
      img{
        width: 25px;
      }
    }
    .googlepicto{
      width: 35px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  &_container{
    &_list{
      padding: 0 30px !important;
      .swiper-wrapper{
        padding: 10px 0;
      }
      &_item{
        border: 1px solid #D9D9D9;
        padding: 30px 25px;
        &_note{
          margin-bottom: 2rem;
          img{
            width: 25px;
          }
        }
        &_description{
          margin-bottom: 3rem;
          position: relative;
          p{
            font-size: 13pt;
            line-height: 15pt;
          }

          &_container{
            position: relative;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            max-height: 10em;
            overflow: hidden;
            transition: all .5s ease;
            &.noHidden{
              -webkit-line-clamp: unset;
              max-height: unset;
            }
          }
          .moreAvis{
            cursor: pointer;
            position: absolute;
            bottom: -25px;
            left: 0;
            text-decoration: underline;
            &.hidden{
              display: none;
            }
          }
        }
        &_auteurInfo{
          p{
            color: #808080;
            font-size: 13pt;
            font-weight: 700;
            line-height: 15pt;
          }
        }
      }
    }
    .navigationButton{
      position: absolute;
      inset: 0;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      top: 50%;
      transform: translateY(-50%);
      height: fit-content;
      #avisPrev, #avisNext{
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 768px){
  .avis{
    padding: 0 15px;
    max-width: 1280px;
    margin: 14rem auto 9rem;
    &_title{
      margin-bottom: 3rem;
    }
    &_container{
      max-width: 1280px;
      margin: 0 auto 9rem;
      &_list{
        &_item{
          &_description{
            margin-bottom: 5rem;
            position: relative;
            cursor: pointer;
          }
        }
      }
    }
  }
}