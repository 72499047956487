.expertsArtisans{
  padding: 40px 0;
  &_container {
    &_left {
      &_title {
        padding: 0 15px;
        margin: 0 0 5%;

        h2 {
          color: #869AAB;
        }
      }

      &_nbArtisans {
        padding: 40px 20px;
        background-color: #F0F0F0;
        text-align: center;
        p{
          line-height: normal;
          strong{
            font-size: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 6.9rem;
          }
          i{
            strong{
              font-size: 1.6rem;
              height: auto;
            }
          }
        }
        .swiperNavigation{
          display: none;
        }
      }
    }

    &_right {
      &_list {
        &_swiper {
          img {
            width: 100%;
          }

          .navigationButton {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3rem;
            margin-top: 5%;

            #expertsArtisansPrev, #expertsArtisansNext {
              opacity: 1;
              transition: .3s all ease;

              &.swiper-button-disabled {
                opacity: 0;
                transition: .3s all ease;
              }
            }
          }
        }
      }
    }
  }
  &_description{
    padding: 0 15px;
    margin: 5% 0;
  }
}

@media screen and (min-width: 768px) {
  .expertsArtisans{
    max-width: 1280px;
    margin: 9rem auto 15rem;
    &_container{
      display: flex;
      &_left{
        flex: 0 0 50%;
        &_title{
          padding-left: 35px;
        }
        &_nbArtisans{
          height: 99%;
          display: flex;
          flex-direction: column;
          position: relative;
          //gap: 18rem;
          .swiperNavigation{
            display: flex;
            justify-content: end;
            gap: 3rem;
            #expertsArtisansPrev, #expertsArtisansNext{
              transition: .3s all ease;
              cursor: pointer;
              &.swiper-button-disabled{
                opacity: 0;
                transition: .3s all ease;
              }
            }
          }

          &:before{
            box-shadow: 0 40vw 0 50vw #F0F0F0;
            -webkit-clip-path: inset(0 -50vw 0 -40vw);
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
            pointer-events: none;
          }
        }
      }
      &_right{
        flex: 0 0 50%;
        width: 50%;
        position: relative;
        &_list_swiper{
          .navigationButton{
            position: absolute;
            left: -5%;
          }
        }
      }
    }
    &_description{
      //padding: 2rem 10rem;
      //background-color: white;
      position: relative;
      //width: 80%;
      //margin: 0 10rem 0;
      p{
        text-align: left;
        //font-size: 24px;
        //line-height: 29px;
        font-size: 14pt;
        line-height: 22pt;
      }
    }
  }
}