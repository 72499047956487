header {
  position: sticky;
  overflow: hidden;
  z-index: 2;
  //position: relative;
  top: 0;
  width: 100%;
  background: unset;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

  .header {
    //background-color: #4C4C4C;
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 0 5px;

    &_logo {
      a{
        &:after{
          content: unset;
        }
      }
      img {
        height: 100%;
      }
      h1{
        opacity: 0;
        position: absolute;
        width: 0;
        height: 0;
        inset: 0;
        pointer-events: none;
      }
    }

    &_burger {
      cursor: pointer;
      z-index: 2;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 20%;

      .menu {
        transition: all .4s ease;
        width: 3.7rem;
        height: 3.7rem;
        position: relative;
        cursor: pointer;
        z-index: 20;
        pointer-events: none;

        span {
          width: 25px;
          height: 2px;
          background-color: white;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          transition: opacity .4s ease, transform .4s ease;

          &:first-child {
            top: 1rem;
          }

          &:nth-child(2) {
            top: 50%;
            transform: translate(-50%, -50%);
          }

          &:nth-child(3) {
            bottom: 1rem;
          }
        }

        &.active {
          transition: all .4s ease;

          span {
            &:first-child {
              top: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
            }

            &:nth-child(2) {
              opacity: 0;
            }

            &:nth-child(3) {
              bottom: 50%;
              transform: translate(-50%, 50%) rotate(-45deg);
            }
          }
        }
      }
    }

    &_menu {
      z-index: 2;
      position: fixed;
      top: 80px;
      transition: .3s all ease;
      width: 100vw;
      height: calc(100vh - 80px);
      //background-color: #4C4C4C;
      left: 0;
      transform: translateX(100%);
      display: flex;
      flex-direction: column;
      gap: 3rem;
      align-items: center;
      text-align: center;
      padding-top: 5rem;

      &_list, &_contact {
        display: flex;
        flex-direction: column;
        gap: 3rem;

        a {
          color: white;
          font-size: 2.5rem;
        }
        .itemMenu{
          position: relative;

          a{
            font-size: 2.2rem;
          }
          .subMenu{
            width: 90%;
            text-align: left;
            padding-left: 10rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            max-height: 0px;
            overflow: hidden;
            transition: .3s all ease;
            a{
              line-height: 3rem;
            }
          }
          .arrow{
            position: absolute;
            top: 0;
            right: 7%;
            transition: .3s all ease;
            svg{
              path{
                stroke: white
              }
            }
          }
          &.active{
            .subMenu{
              padding-top: 3rem;
              padding-bottom: 5rem;
              max-height: 500px;
              transition: .3s all ease;
            }

            .arrow{
              transition: .3s all ease;
              transform: rotate(90deg);
            }
          }
        }
      }

      &.open {
        transform: translateX(0%);
        transition: .3s all ease;
      }
    }
  }

  &.menuOpen {
    //position: relative;
    width: 100vw;
    overflow: initial;

    position: sticky;
    top: 0;

    .header_menu.open {
      background-color: #565656;
      position: fixed;
      overflow: hidden;
    }
  }
}

@media screen and (min-width: 1070px) {
  header {
    position: fixed;
    overflow: unset;

    .header {
      overflow: unset;
      padding: 0 40px;
      gap: 7rem;
      //max-width: 1280px;
      margin: 0 auto;
      position: relative;

      &_burger {
        display: none;
      }

      &_menu {
        position: relative;
        width: auto;
        height: auto;
        transform: translateX(0);
        flex-direction: row;
        inset: 0;
        padding: unset;
        flex-grow: 1;
        justify-content: space-between;

        &_list, &_contact {
          flex-direction: row;
        }

        &_list {
          gap: 7rem;

          a {
            font-size: 1.6rem;
            line-height: 1.9rem;
          }

          .itemMenu {
            position: relative;
            a{
              font-size: 1.6rem;
              line-height: 1.9rem;
            }
            .arrow{
              display: none;
            }

            .subMenu {
              position: relative;
              transition: .3s all ease;
              max-height: 0px;
              padding: 0;
              overflow: hidden;

              display: flex;
              flex-direction: column;
              gap: 2rem;
              text-align: left;
              position: absolute;
              top: 70%;
              max-width: 250px;
              width: max-content;


              pointer-events: auto;
              margin-top: 5rem;

              background-color: rgba(0,0,0,0.7);
              -webkit-backdrop-filter: blur(8px);
              backdrop-filter: blur(8px);

              a {
                color: white;
                width: fit-content;
                text-align: left;
                //margin: 2rem;
                margin: 0 2rem;
                &:first-of-type{
                  margin: 2rem 2rem 0;
                }
                &:last-child{
                  margin: 0 2rem 2rem;
                }
              }
              &:before{
                box-shadow: 0 40vw 0 40vw #4C4C4C;
                -webkit-clip-path: inset(0 -40vw 0 -40vw);
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 0;
                pointer-events: none;
              }
            }

            &:hover {
              .subMenu {
                transition: .3s all ease;
                max-height: 250px;
              }
            }
          }
        }

        &_contact {
          &_tel {
            display: flex;
            align-items: center;
            gap: 1rem;

            a {
              font-size: 1.6rem;
              line-height: 1.9rem;
              display: flex;
              align-items: center;
              gap: 1rem;
            }
          }
        }
      }

      &:before {
        box-shadow: 0 40vw 0 40vw #4C4C4C;
        -webkit-clip-path: inset(0 -40vw 0 -40vw);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
        pointer-events: none;
      }
    }
  }
}