@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
*, html, body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

h1 {
  font-size: 3.2rem;
  line-height: 3.5rem;
  font-family: "Inter", sans-serif;
}
h1 strong {
  font-size: 3.2rem;
  line-height: 3.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

h2 {
  font-size: 3.2rem;
  line-height: 3.5rem;
  font-family: "Cairo", sans-serif;
  font-weight: 700;
}
h2 span, h2 strong {
  font-size: 3.2rem;
  line-height: 3.5rem;
  font-family: "Cairo", sans-serif;
  font-weight: 700;
}

h3 {
  font-size: 2.8rem;
  line-height: 3.5rem;
  font-family: "Cairo", sans-serif;
}
h3 span, h3 strong {
  font-size: 2.8rem;
  line-height: 3.5rem;
  font-family: "Cairo", sans-serif;
  font-weight: 700;
}

h4 {
  font-size: 14pt;
  line-height: 22pt;
  font-style: italic;
}

p, a {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
}
p strong, a strong {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  font-weight: 700;
}
p i, a i {
  font-style: italic;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
}
p a, a a {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  color: #869AAB;
  transition: 0.3s all ease;
}
p a:hover, a a:hover {
  color: black;
  transition: 0.3s all ease;
}

a {
  position: relative;
}
a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}
a:hover:after {
  transform: scaleX(100%);
}
a.white:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: black;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}
a.white:hover:after {
  transform: scaleX(100%);
}

.honung {
  display: none;
}

.btn {
  width: max-content;
  text-align: center;
  margin: 0 auto;
  display: flex;
  background-color: black;
  border: 1px solid black;
}
.btn a {
  padding: 15px 25px;
  color: white;
  width: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
.btn a p {
  cursor: pointer;
  color: white;
}
.btn a:after {
  display: none;
}
.btn.transparent {
  background: transparent;
  border: 1px solid white;
}
.btn.transparent p {
  color: white;
}
.btn:hover {
  background-color: white;
  border: 1px solid black;
}
.btn:hover a p {
  color: black;
}
.btn.whiteColor {
  background-color: white;
}
.btn.whiteColor a p {
  color: black;
}
.btn.whiteColor:hover {
  background-color: black;
  border: 1px solid white;
}
.btn.whiteColor:hover a p {
  color: white;
}

header {
  position: sticky;
  overflow: hidden;
  z-index: 2;
  top: 0;
  width: 100%;
  background: unset;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
header .header {
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding: 0 5px;
}
header .header_logo a:after {
  content: unset;
}
header .header_logo img {
  height: 100%;
}
header .header_logo h1 {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  inset: 0;
  pointer-events: none;
}
header .header_burger {
  cursor: pointer;
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 20%;
}
header .header_burger .menu {
  transition: all 0.4s ease;
  width: 3.7rem;
  height: 3.7rem;
  position: relative;
  cursor: pointer;
  z-index: 20;
  pointer-events: none;
}
header .header_burger .menu span {
  width: 25px;
  height: 2px;
  background-color: white;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  transition: opacity 0.4s ease, transform 0.4s ease;
}
header .header_burger .menu span:first-child {
  top: 1rem;
}
header .header_burger .menu span:nth-child(2) {
  top: 50%;
  transform: translate(-50%, -50%);
}
header .header_burger .menu span:nth-child(3) {
  bottom: 1rem;
}
header .header_burger .menu.active {
  transition: all 0.4s ease;
}
header .header_burger .menu.active span:first-child {
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
header .header_burger .menu.active span:nth-child(2) {
  opacity: 0;
}
header .header_burger .menu.active span:nth-child(3) {
  bottom: 50%;
  transform: translate(-50%, 50%) rotate(-45deg);
}
header .header_menu {
  z-index: 2;
  position: fixed;
  top: 80px;
  transition: 0.3s all ease;
  width: 100vw;
  height: calc(100vh - 80px);
  left: 0;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  text-align: center;
  padding-top: 5rem;
}
header .header_menu_list, header .header_menu_contact {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
header .header_menu_list a, header .header_menu_contact a {
  color: white;
  font-size: 2.5rem;
}
header .header_menu_list .itemMenu, header .header_menu_contact .itemMenu {
  position: relative;
}
header .header_menu_list .itemMenu a, header .header_menu_contact .itemMenu a {
  font-size: 2.2rem;
}
header .header_menu_list .itemMenu .subMenu, header .header_menu_contact .itemMenu .subMenu {
  width: 90%;
  text-align: left;
  padding-left: 10rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 0px;
  overflow: hidden;
  transition: 0.3s all ease;
}
header .header_menu_list .itemMenu .subMenu a, header .header_menu_contact .itemMenu .subMenu a {
  line-height: 3rem;
}
header .header_menu_list .itemMenu .arrow, header .header_menu_contact .itemMenu .arrow {
  position: absolute;
  top: 0;
  right: 7%;
  transition: 0.3s all ease;
}
header .header_menu_list .itemMenu .arrow svg path, header .header_menu_contact .itemMenu .arrow svg path {
  stroke: white;
}
header .header_menu_list .itemMenu.active .subMenu, header .header_menu_contact .itemMenu.active .subMenu {
  padding-top: 3rem;
  padding-bottom: 5rem;
  max-height: 500px;
  transition: 0.3s all ease;
}
header .header_menu_list .itemMenu.active .arrow, header .header_menu_contact .itemMenu.active .arrow {
  transition: 0.3s all ease;
  transform: rotate(90deg);
}
header .header_menu.open {
  transform: translateX(0%);
  transition: 0.3s all ease;
}
header.menuOpen {
  width: 100vw;
  overflow: initial;
  position: sticky;
  top: 0;
}
header.menuOpen .header_menu.open {
  background-color: #565656;
  position: fixed;
  overflow: hidden;
}

@media screen and (min-width: 1070px) {
  header {
    position: fixed;
    overflow: unset;
  }
  header .header {
    overflow: unset;
    padding: 0 40px;
    gap: 7rem;
    margin: 0 auto;
    position: relative;
  }
  header .header_burger {
    display: none;
  }
  header .header_menu {
    position: relative;
    width: auto;
    height: auto;
    transform: translateX(0);
    flex-direction: row;
    inset: 0;
    padding: unset;
    flex-grow: 1;
    justify-content: space-between;
  }
  header .header_menu_list, header .header_menu_contact {
    flex-direction: row;
  }
  header .header_menu_list {
    gap: 7rem;
  }
  header .header_menu_list a {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  header .header_menu_list .itemMenu {
    position: relative;
  }
  header .header_menu_list .itemMenu a {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  header .header_menu_list .itemMenu .arrow {
    display: none;
  }
  header .header_menu_list .itemMenu .subMenu {
    position: relative;
    transition: 0.3s all ease;
    max-height: 0px;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: left;
    position: absolute;
    top: 70%;
    max-width: 250px;
    width: max-content;
    pointer-events: auto;
    margin-top: 5rem;
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
  }
  header .header_menu_list .itemMenu .subMenu a {
    color: white;
    width: fit-content;
    text-align: left;
    margin: 0 2rem;
  }
  header .header_menu_list .itemMenu .subMenu a:first-of-type {
    margin: 2rem 2rem 0;
  }
  header .header_menu_list .itemMenu .subMenu a:last-child {
    margin: 0 2rem 2rem;
  }
  header .header_menu_list .itemMenu .subMenu:before {
    box-shadow: 0 40vw 0 40vw #4C4C4C;
    -webkit-clip-path: inset(0 -40vw 0 -40vw);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    pointer-events: none;
  }
  header .header_menu_list .itemMenu:hover .subMenu {
    transition: 0.3s all ease;
    max-height: 250px;
  }
  header .header_menu_contact_tel {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  header .header_menu_contact_tel a {
    font-size: 1.6rem;
    line-height: 1.9rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  header .header:before {
    box-shadow: 0 40vw 0 40vw #4C4C4C;
    -webkit-clip-path: inset(0 -40vw 0 -40vw);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    pointer-events: none;
  }
}
.footer {
  background-color: black;
  text-align: center;
}
.footer_top {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 30px 15px;
}
.footer_top_left_logo {
  text-align: center;
}
.footer_top_left_description {
  color: #869AAB;
}
.footer_top_center_title h3 {
  font-size: 1.6rem;
  color: #869AAB;
  margin-bottom: 1rem;
}
.footer_top_center_list {
  display: flex;
  flex-direction: column;
}
.footer_top_center_list a, .footer_top_center_list p {
  color: white;
  font-size: 1.3rem;
}
.footer_top_center_list ul {
  padding-left: 2rem;
}
.footer_top_right {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.footer_top_right_aPropos_title h3 {
  font-size: 1.6rem;
  color: #869AAB;
  margin-bottom: 1rem;
}
.footer_top_right_aPropos_list {
  display: flex;
  flex-direction: column;
}
.footer_top_right_aPropos_list a, .footer_top_right_aPropos_list p {
  color: white;
  font-size: 1.3rem;
}
.footer_top_right_info {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 260px;
  margin: 0 auto;
}
.footer_top_right_info_adresse {
  display: flex;
  gap: 1rem;
}
.footer_top_right_info_adresse_info {
  text-align: left;
}
.footer_top_right_info_tel {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.footer_top_right_info_tel_info {
  text-align: left;
}
.footer_top_right_info_tel_info a {
  color: white;
}
.footer_bottom {
  padding: 30px 15px;
  border-top: 1px solid white;
}
.footer_bottom_container p {
  color: white;
  text-align: center;
  margin-bottom: 1rem;
}
.footer_bottom_container a {
  color: white !important;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .footer {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
  }
  .footer_top {
    padding: 60px 80px;
    flex-direction: row;
    justify-content: space-between;
  }
  .footer_top_left, .footer_top_center, .footer_top_right {
    flex: 0 0 30%;
  }
  .footer_top_left {
    max-width: 390px;
  }
  .footer_top_left_logo {
    margin-bottom: 3rem;
    text-align: left;
    height: 130px;
  }
  .footer_top_left_logo img {
    height: 100%;
  }
  .footer_top_left_description {
    text-align: left;
  }
  .footer_top_left_description p {
    font-size: 13pt;
  }
  .footer_top_center {
    max-width: 280px;
    text-align: left;
  }
  .footer_top_center_title h3 {
    font-size: 16pt;
    margin-bottom: 3rem;
  }
  .footer_top_center_list {
    gap: 2rem;
  }
  .footer_top_center_list a, .footer_top_center_list p {
    width: fit-content;
    font-size: 13pt;
  }
  .footer_top_center_list ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .footer_top_center_list ul li::before {
    color: white;
    content: "•";
    padding-right: 8px;
  }
  .footer_top_right {
    max-width: 300px;
    justify-content: space-between;
    gap: unset;
  }
  .footer_top_right_aPropos_title h3 {
    text-align: left;
    font-size: 16pt;
    margin-bottom: 3rem;
  }
  .footer_top_right_aPropos_list {
    text-align: left;
    gap: 2rem;
  }
  .footer_top_right_aPropos_list a, .footer_top_right_aPropos_list p {
    width: fit-content;
    font-size: 13pt;
  }
  .footer_top_right_info {
    max-width: unset;
    margin: 0;
    gap: 3rem;
  }
  .footer_top_right_info_adresse p, .footer_top_right_info_adresse a, .footer_top_right_info_tel p, .footer_top_right_info_tel a {
    font-size: 13pt;
    text-decoration: none;
    color: white;
  }
  .footer_top_right_info_adresse_info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .footer_bottom {
    padding: 60px 80px;
  }
  .footer:before {
    box-shadow: 0 40vw 0 40vw black;
    -webkit-clip-path: inset(0 -40vw 0 -40vw);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    pointer-events: none;
  }
}
.homepage_heroBanner {
  position: relative;
  overflow: hidden;
}
.homepage_heroBanner_swiper {
  overflow: unset !important;
}
.homepage_heroBanner_swiper_list {
  height: auto;
}
.homepage_heroBanner_swiper_list_item {
  height: auto !important;
}
.homepage_heroBanner_swiper_list_item_slide {
  height: auto;
}
.homepage_heroBanner_swiper_list_item img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.homepage_heroBanner_swiper .navigationButton {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  pointer-events: none;
}
.homepage_heroBanner_swiper .navigationButton #hpHeroBannerPrev, .homepage_heroBanner_swiper .navigationButton #hpHeroBannerNext {
  pointer-events: auto;
  cursor: pointer;
}
.homepage_heroBanner_title {
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.homepage_heroBanner_title_subTitle {
  margin: 3rem;
  text-align: left;
  display: none;
}
.homepage_heroBanner_title_subTitle h1 {
  font-size: 14pt;
  line-height: 14pt;
}
.homepage_heroBanner_title_header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 15px;
  margin-top: -5px;
  background-color: black;
}
.homepage_heroBanner_title_header h2 {
  color: white;
  font-size: 3rem;
  font-weight: 700;
}
.homepage_heroBanner_title_header h1 {
  color: white;
  font-size: 1.4rem;
  line-height: 2rem;
}
.homepage_heroBanner_title_chapeau p {
  font-size: 2rem;
  text-align: center;
  color: #869AAB;
  font-family: "Cairo", sans-serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.homepage_heroBanner_title_chapeau p div {
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
  color: #869AAB;
  font-family: "Cairo", sans-serif;
}
.homepage .zoneIntervention {
  padding: 30px 0 0;
}
.homepage .zoneIntervention_container {
  padding: 0 15px;
  text-align: center;
  margin-bottom: 3rem;
}
.homepage .zoneIntervention_container_title {
  margin-bottom: 3rem;
}
.homepage .zoneIntervention_container_title h2 {
  font-size: 32pt;
  line-height: 32pt;
  font-weight: 700;
}
.homepage .zoneIntervention_container_sousTitre p {
  font-size: 13pt;
  line-height: 15pt;
}
.homepage .zoneIntervention_iframe {
  height: 300px;
}
.homepage .zoneIntervention_iframe iframe {
  width: 100%;
  height: 100%;
}
.homepage .realisations {
  max-width: 1280px;
  margin: 0 auto;
}
.homepage .realisations_container_left {
  padding: 30px 15px;
}
.homepage .realisations_container_left_title {
  text-align: center;
  margin-top: 3rem;
}
.homepage .realisations_container_left_title h2 {
  font-size: 32pt;
  line-height: 32pt;
  font-weight: 700;
}
.homepage .realisations_container_left ul {
  overflow: scroll;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  gap: 3rem;
  padding: 30px 0 30px 15px;
  align-items: center;
}
.homepage .realisations_container_left ul li {
  flex: 0 0 65%;
  text-align: center;
}
.homepage .realisations_container_left ul li p {
  color: #D9D9D9;
  font-size: 16pt;
  line-height: 16pt;
  font-weight: 700;
  transition: 0.3s all ease;
}
.homepage .realisations_container_left ul li p:hover {
  color: black;
  transition: 0.3s all ease;
}
.homepage .realisations_container_left ul li.active p {
  color: black;
}
.homepage .realisations_container_right_list_swiper {
  display: none;
  transition: 0.3s all ease;
}
.homepage .realisations_container_right_list_swiper .sliderTop {
  height: 250px;
  margin-bottom: 1rem;
}
.homepage .realisations_container_right_list_swiper .sliderTop img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.homepage .realisations_container_right_list_swiper .sliderBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.homepage .realisations_container_right_list_swiper .sliderBottom_left {
  font-size: 14pt;
  font-weight: 400;
  line-height: 16pt;
}
.homepage .realisations_container_right_list_swiper .sliderBottom_left a {
  color: black;
}
.homepage .realisations_container_right_list_swiper.active {
  display: block;
}
.homepage .realisations_container_right_list .swiper-button-disabled {
  opacity: 0;
  transition: 0.3s all ease;
}
.homepage .realisations_container_right .sliderBottom {
  display: none;
}
.homepage .realisations_container_right .sliderBottom_left {
  text-align: center;
  margin-bottom: 1rem;
}
.homepage .realisations_container_right .sliderBottom.active {
  display: block;
}

@media screen and (min-width: 768px) {
  .homepage_heroBanner_swiper_list {
    height: 70vh;
  }
  .homepage_heroBanner_swiper_list_item {
    height: 70vh !important;
  }
  .homepage_heroBanner_title {
    max-width: 1280px;
    margin: 0 auto;
  }
  .homepage_heroBanner_title.chapeauHomepage {
    margin-top: 5rem;
  }
  .homepage_heroBanner_title_subTitle {
    display: block;
  }
  .homepage_heroBanner_title_header {
    text-align: left;
    margin-bottom: 10rem;
    position: absolute;
    bottom: 14%;
    max-width: 650px;
    padding: 50px 115px 50px 115px;
    z-index: 1;
  }
  .homepage_heroBanner_title_header h2 {
    font-size: 3.2rem;
    line-height: 3.5rem;
    max-width: 520px;
    margin-bottom: 2rem;
  }
  .homepage_heroBanner_title_header h1 {
    font-size: 14pt;
    line-height: 14pt;
  }
  .homepage_heroBanner_title_chapeau {
    max-width: 900px;
    margin: 0 auto;
  }
  .homepage_heroBanner_title_chapeau p {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 24pt;
    line-height: 35pt;
  }
  .homepage_heroBanner_title_chapeau p div {
    font-size: 24pt;
    line-height: 35pt;
    text-align: center;
    color: #869AAB;
    font-family: "Cairo", sans-serif;
  }
  .homepage_heroBanner_title_chapeau p div strong {
    font-size: 24pt;
    line-height: 35pt;
    text-align: center;
    font-family: "Cairo", sans-serif;
  }
  .homepage .zoneIntervention {
    max-width: 1280px;
    margin: 5rem auto 10rem;
  }
  .homepage .zoneIntervention_container {
    max-width: 1000px;
    margin: 3rem auto;
  }
  .homepage .zoneIntervention_iframe {
    height: 500px;
    width: 60%;
    margin: 0 auto;
  }
  .homepage .realisations {
    position: relative;
  }
  .homepage .realisations_container {
    display: flex;
    justify-content: end;
    gap: 5rem;
  }
  .homepage .realisations_container_left {
    flex: 0 0 28%;
  }
  .homepage .realisations_container_left_title {
    margin-bottom: 5rem;
  }
  .homepage .realisations_container_left ul {
    overflow: auto;
    flex-direction: column;
    align-items: start;
  }
  .homepage .realisations_container_left ul li {
    cursor: pointer;
    text-align: left;
    font-size: 14pt;
  }
  .homepage .realisations_container_left ul li:not(:last-child) {
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 4rem;
  }
  .homepage .realisations_container_right {
    height: 580px;
    flex: 0 0 55%;
    width: 10%;
  }
  .homepage .realisations_container_right_list {
    height: 100%;
  }
  .homepage .realisations_container_right_list_swiper {
    height: 100%;
  }
  .homepage .realisations_container_right_list_swiper .sliderTop {
    height: 100%;
    margin-bottom: unset;
  }
  .homepage .realisations_container_right_list_swiper .sliderTop img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  .homepage .realisations_container_right_list .sliderBottom {
    display: none;
    flex-direction: row;
    justify-content: end;
    gap: 0;
    align-items: stretch;
    position: relative;
  }
  .homepage .realisations_container_right_list .sliderBottom .navigationButton {
    cursor: pointer;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .homepage .realisations_container_right_list .sliderBottom .navigationButton #realPrev0:hover, .homepage .realisations_container_right_list .sliderBottom .navigationButton #realNext0:hover {
    animation: bounce 2s ease infinite;
  }
  .homepage .realisations_container_right_list .sliderBottom_left {
    margin-bottom: unset;
    padding: 0 10px;
    width: 40%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .homepage .realisations_container_right_list .sliderBottom_left a {
    color: var(--black);
  }
  .homepage .realisations_container_right_list .sliderBottom_right {
    width: 40%;
  }
  .homepage .realisations_container_right_list .sliderBottom_right .btn {
    width: 100%;
  }
  .homepage .realisations_container_right_list .sliderBottom_right .btn a {
    width: 100%;
    justify-content: center;
  }
  .homepage .realisations_container_right_list .sliderBottom.active {
    display: flex;
  }
}
.textImage {
  margin: 5rem 0;
}
.textImage_list {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.textImage_list_item_left {
  width: 100%;
}
.textImage_list_item_left img {
  width: 100%;
}
.textImage_list_item_right {
  padding: 15px;
}
.textImage_list_item_right_text h3 {
  font-size: 25pt;
  font-family: "Cairo", sans-serif;
  color: #869AAB;
  line-height: 35pt;
}
.textImage_list_item_right_text h3 a {
  font-size: 25pt;
  font-family: "Cairo", sans-serif;
  color: #869AAB;
  line-height: 35pt;
  text-decoration: unset;
}
.textImage_list_item_right_text h3 a strong {
  font-weight: 700;
  font-size: 25pt;
  font-family: "Cairo", sans-serif;
  color: #869AAB;
}
.textImage_list_item_right_text h3 a strong a {
  font-weight: 700 !important;
}
.textImage_list_item_right_text h3 strong {
  font-weight: 700;
  font-size: 25pt;
  font-family: "Cairo", sans-serif;
  color: #869AAB;
}
.textImage_list_item_right_text h3 strong a {
  font-weight: 700;
}
.textImage_list_item_right_text p, .textImage_list_item_right_text a {
  color: black;
  font-size: 16pt;
  line-height: 22pt;
}
.textImage_list_item_right_text p strong, .textImage_list_item_right_text a strong {
  font-weight: 700;
  color: black;
  font-size: 16pt;
  line-height: 22pt;
}
.textImage_list_item_right_text a {
  text-decoration: underline;
}
.textImage_list_item_right .btn {
  margin-top: 3rem;
  width: 100%;
}
.textImage_list_item_right .btn a {
  width: 100%;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .textImage {
    max-width: 1280px;
    margin: 9rem auto;
  }
  .textImage_list {
    gap: 10rem;
  }
  .textImage_list_item {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
  }
  .textImage_list_item_left {
    flex: 0 0 48%;
    opacity: 0;
    transform: translateX(-50px);
    transition: 0.3s all ease;
  }
  .textImage_list_item_left img {
    height: 100%;
    object-fit: cover;
  }
  .textImage_list_item_right {
    height: fit-content;
    flex: 0 0 46%;
    opacity: 0;
    transform: translateX(50px);
    transition: 0.3s all ease;
  }
  .textImage_list_item_right_text h3 {
    font-size: 27pt;
  }
  .textImage_list_item_right_text h3 strong {
    font-size: 27pt;
  }
  .textImage_list_item_right .btn {
    width: fit-content;
    margin: 3rem 0 0;
  }
  .textImage_list_item:nth-child(2n) {
    flex-direction: row-reverse;
  }
  .textImage_list_item:nth-child(2n) .textImage_list_item_left {
    transform: translateX(50px);
  }
  .textImage_list_item:nth-child(2n) .textImage_list_item_right {
    transform: translateX(-50px);
  }
  .textImage_list_item.revealOn .textImage_list_item_left {
    flex: 0 0 48%;
    opacity: 1;
    transform: translateX(0);
    transition: 0.5s all ease;
  }
  .textImage_list_item.revealOn .textImage_list_item_right {
    opacity: 1;
    transform: translateX(0);
    transition: 0.5s all ease;
  }
}
.pageSimple-content {
  width: 90%;
  margin: 5rem auto;
}
.pageSimple-content-header {
  margin-bottom: 5rem;
}
.pageSimple-content-header h1 {
  text-align: center;
  color: black;
}
.pageSimple-content .list_pageSimple {
  margin-top: 10rem;
}
.pageSimple-content .list_pageSimple_content {
  margin-bottom: 5rem;
}
.pageSimple-content .list_pageSimple_content h2 {
  line-height: 3.5rem;
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 2rem;
  color: black;
}
.pageSimple-content .list_pageSimple_content p {
  font-size: 1.5rem;
  color: black;
}

@media screen and (min-width: 768px) {
  .pageSimple-content {
    width: 80%;
    margin: 15rem auto 0;
    padding-bottom: 5rem;
  }
  .pageSimple-content .list_pageSimple {
    width: 80%;
    margin: 10% auto 10%;
  }
}
.unProjet {
  background: #F0F0F0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
}
.unProjet_text p {
  font-size: 16px;
}
.unProjet_text p.projet {
  font-weight: 700;
}
.unProjet_btn .btn {
  width: 100%;
  justify-content: center;
}

.chiffreCles {
  background-color: #869AAB;
  padding: 30px 15px;
}
.chiffreCles_list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.chiffreCles_list_item {
  text-align: center;
}
.chiffreCles_list_item p {
  color: white;
  font-family: "Cairo", sans-serif;
}
.chiffreCles_list_item p.chiffre {
  font-size: 60pt;
  line-height: 50pt;
  font-weight: 700;
}
.chiffreCles_list_item p.sousChiffre {
  font-size: 24pt;
  line-height: 18pt;
  font-weight: 700;
  margin-bottom: 3rem;
}
.chiffreCles_list_item p.descriptionChiffre {
  font-size: 21pt;
  line-height: 21pt;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .chiffreCles {
    margin: 9rem 0 0;
  }
  .chiffreCles_list {
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 1280px;
    margin: 6rem auto;
  }
  .chiffreCles_list_item {
    flex: 0 0 17%;
  }
}
.contenuSimple {
  position: relative;
  text-align: center;
  overflow: hidden;
}
.contenuSimple .pattern {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 90%;
  object-fit: contain;
  transform: translateY(-10%);
  filter: opacity(0.7);
}
.contenuSimple_container {
  position: relative;
  z-index: 1;
  padding: 30px 15px;
}
.contenuSimple_container_description {
  margin-bottom: 5rem;
}
.contenuSimple_container_description h2 {
  font-size: 24pt;
  line-height: 25pt;
  font-weight: 700;
  margin-bottom: 3rem;
  font-family: Inter, sans-serif;
}
.contenuSimple_container_description p {
  font-size: 2rem;
  line-height: 2rem;
}
.contenuSimple_container .btn {
  margin-top: 3rem;
}

@media screen and (min-width: 768px) {
  .contenuSimple .pattern {
    transform: translateY(-30%);
  }
  .contenuSimple_container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 9rem 15px 12rem;
  }
  .contenuSimple_container_description {
    max-width: 1000px;
    margin: 0 auto;
  }
  .contenuSimple_container_description p, .contenuSimple_container_description h2 {
    font-size: 21pt;
    line-height: 21pt;
  }
}
.contact_title {
  padding: 14px;
}
.contact_info {
  position: relative;
  padding: 5rem 14px;
}
.contact_info_title {
  margin-bottom: 5rem;
}
.contact_info_container_left {
  margin-bottom: 5rem;
}
.contact_info_container_left ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.contact_info_container_left ul li:not(:last-child) {
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 3rem;
}
.contact_info_container_left ul li p.boldParagraph {
  font-weight: 700;
  margin-bottom: 1rem;
}
.contact_info_container_left ul li a {
  text-decoration: none;
  color: black;
}

@media screen and (min-width: 768px) {
  .contact_title {
    max-width: 1280px;
    margin: 0 auto;
  }
  .contact_info_title {
    max-width: 1280px;
    margin: 0 auto 5rem;
  }
  .contact_info_container {
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    gap: 10rem;
  }
  .contact_info_container_right {
    flex: 0 0 31%;
  }
}
.formulaire {
  padding: 0 14px;
}
.formulaire_title h2 {
  font-weight: 700;
  text-align: center;
  margin: 5rem 0;
}
.formulaire form #dynamic_formContact1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.formulaire form #dynamic_formContact1 ul {
  list-style-type: none;
}
.formulaire form #dynamic_formContact1 ul li {
  text-align: left;
  color: red;
  font-size: 1.3rem;
  margin-bottom: 5px;
}
.formulaire form #dynamic_formContact1 input, .formulaire form #dynamic_formContact1 textarea {
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid #7F8080;
  color: #504F4F;
  font-size: 1.6rem;
  outline: none;
  padding: 1.5rem 0 1.5rem 2rem;
  width: 100%;
  margin-bottom: 1rem;
}
.formulaire form #dynamic_formContact1 input::placeholder, .formulaire form #dynamic_formContact1 textarea::placeholder {
  color: #504F4F;
  font-size: 1.6rem;
}
.formulaire form #dynamic_formContact1 .choice {
  text-align: left;
  margin-bottom: 4%;
}
.formulaire form #dynamic_formContact1 .choice input[type=checkbox] {
  width: 20px;
  height: 20px;
  padding: 0;
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 3%;
  outline: none;
  vertical-align: middle;
}
.formulaire form #dynamic_formContact1 .choice input[type=checkbox]:checked {
  background-color: black;
}
.formulaire form #dynamic_formContact1 .choice label {
  display: block;
  padding-left: 32px;
  margin-top: -8%;
  font-size: 1.2rem;
  color: #504F4F;
}
.formulaire form #dynamic_formContact1 .choice label a {
  text-decoration: underline;
  line-height: inherit;
  font-size: 1.6rem;
  color: #504F4F;
}
.formulaire form #dynamic_formContact1 .width-half {
  display: block;
  width: 48%;
  text-align: center;
  margin-bottom: 1rem;
}
.formulaire form #dynamic_formContact1 .width-full {
  width: 100%;
  text-align: center;
}
.formulaire form #dynamic_formContact1 button {
  -webkit-appearance: none;
  border: 1px solid black;
  background-color: black;
  color: white;
  font-size: 2rem;
  padding: 10px 60px;
  cursor: pointer;
}
.formulaire form #dynamic_formContact1 button:hover {
  background: white;
  color: black;
}
.formulaire form #dynamic_formContact1 .g-recaptcha {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}
.formulaire_iframe {
  overflow: hidden;
}
.formulaire_iframe iframe {
  border: 0;
  overflow: hidden;
}
.formulaire_iframe iframe .form-wrapper-component .form-content-component {
  box-shadow: unset;
}
.formulaire_iframe iframe button {
  background: black;
}

@media screen and (min-width: 768px) {
  .formulaire {
    max-width: 700px;
    margin: 0 auto 5rem;
  }
  .formulaire form #dynamic_formContact1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, 0fr);
    grid-column-gap: 45px;
    grid-row-gap: 20px;
  }
  .formulaire form #dynamic_formContact1 .choice label {
    margin-top: -3%;
  }
  .formulaire form #dynamic_formContact1 .width-full:nth-child(1) {
    grid-area: 1/1/2/2;
  }
  .formulaire form #dynamic_formContact1 .width-full:nth-child(2) {
    grid-area: 2/1/3/2;
  }
  .formulaire form #dynamic_formContact1 .width-full:nth-child(3) {
    grid-area: 3/1/4/2;
  }
  .formulaire form #dynamic_formContact1 .width-full:nth-child(4) {
    grid-area: 4/1/5/2;
  }
  .formulaire form #dynamic_formContact1 .width-full:nth-child(5) {
    grid-area: 1/2/4/3;
  }
  .formulaire form #dynamic_formContact1 .width-full:nth-child(6) {
    grid-area: 4/2/5/3;
  }
  .formulaire form #dynamic_formContact1 .width-full:nth-child(7) {
    grid-area: 5/1/6/3;
  }
  .formulaire form #dynamic_formContact1 .width-full:last-child {
    grid-area: 7/1/7/3;
  }
  .formulaire form #dynamic_formContact1 .width-full:last-child button {
    height: 100%;
  }
  .formulaire form #dynamic_formContact1 .width-full textarea, .formulaire form #dynamic_formContact1 .width-full input {
    height: 100%;
    margin-bottom: unset;
  }
  .formulaire form #dynamic_formContact1 .g-recaptcha {
    grid-area: 6/1/6/3;
  }
}
.recrutement_title {
  padding: 0 14px;
}
.recrutement_chapeau {
  margin: 5rem 0;
  padding: 0 14px;
  text-align: center;
}
.recrutement_offres {
  padding: 0 14px;
  margin: 5rem 0;
}
.recrutement_offres h2 {
  margin-bottom: 3rem;
}
.recrutement_offres_list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.recrutement_spontanee {
  position: relative;
  padding: 3rem 14px;
}
.recrutement_spontanee_container_title {
  margin: 3rem 0;
}
.recrutement_spontanee_container_content_description {
  margin-bottom: 3rem;
}

@media screen and (min-width: 768px) {
  .recrutement_title {
    max-width: 1280px;
    margin: 0 auto;
  }
  .recrutement_chapeau {
    max-width: 1280px;
    margin: 10rem auto;
  }
  .recrutement_chapeau p {
    font-size: 32px;
    line-height: 35px;
  }
  .recrutement_chapeau p strong {
    font-size: 32px;
    line-height: 35px;
  }
  .recrutement_offres {
    max-width: 1280px;
    margin: 5rem auto;
  }
  .recrutement_offres_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
  .recrutement_spontanee_container {
    max-width: 1280px;
    margin: 0 auto;
  }
  .recrutement_spontanee_container_content {
    max-width: 640px;
    margin: 0 auto;
  }
  .recrutement_spontanee_container_content_description p {
    font-size: 24px;
    line-height: 30px;
  }
  .recrutement_spontanee_container_content_btn .btn {
    margin: 0 auto;
  }
}
.offreCard {
  border: 1px solid #BFBFBF;
  position: relative;
}
.offreCard_content {
  padding: 2rem 3rem;
  color: black;
}
.offreCard_content_top {
  border-bottom: 1px solid #BFBFBF;
  padding-bottom: 5rem;
}
.offreCard_content_top_tags {
  margin-bottom: 2rem;
}
.offreCard_content_top_tags p {
  font-style: italic;
  font-size: 1.6rem;
  border-bottom: 13px solid #F0F0F0;
  width: fit-content;
  line-height: 13px;
}
.offreCard_content_top_poste p {
  font-size: 24px;
  font-weight: 700;
}
.offreCard_content_bottom {
  padding-top: 5rem;
}
.offreCard_content_bottom p {
  font-weight: 400;
  font-size: 16px;
}
.offreCard .hover {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s all ease;
  background-color: rgba(240, 240, 240, 0.5);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}
.offreCard .hover p {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.offreCard:hover .hover {
  transition: 0.3s all ease;
  opacity: 1;
}

.labels {
  padding: 30px 15px;
}
.labels_container_title {
  text-align: center;
  margin-bottom: 3rem;
}
.labels_container_title h2 {
  font-size: 32pt;
  line-height: 32pt;
  font-weight: 700;
}
.labels_container_list.defileParent {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
}
.labels_container_list.defileParent .defile {
  display: flex;
  animation: defile 8s linear infinite;
}
.labels_container_list.defileParent .defile li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  margin: 0 5rem;
}
.labels_container_list.defileParent .defile li img {
  width: 100%;
  object-fit: contain;
}
.labels_container_list.defileParent:hover .defile {
  animation-play-state: paused;
}

@media screen and (min-width: 768px) {
  .labels {
    padding: 0 15px;
    margin: 9rem auto 0;
    max-width: 1280px;
  }
  .labels_container_list {
    overflow: auto;
    justify-content: center;
  }
}
@keyframes defile {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}
.avis {
  padding: 30px 15px;
}
.avis_title {
  text-align: center;
  margin-bottom: 3rem;
}
.avis_title h2 {
  font-size: 32pt;
  line-height: 32pt;
  font-weight: 700;
}
.avis_subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  gap: 1rem;
}
.avis_subtitle span {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
}
.avis_subtitle .stars {
  display: flex;
  gap: 0.5rem;
}
.avis_subtitle .stars img {
  width: 25px;
}
.avis_subtitle .googlepicto {
  width: 35px;
}
.avis_subtitle .googlepicto img {
  width: 100%;
  height: 100%;
}
.avis_container_list {
  padding: 0 30px !important;
}
.avis_container_list .swiper-wrapper {
  padding: 10px 0;
}
.avis_container_list_item {
  border: 1px solid #D9D9D9;
  padding: 30px 25px;
}
.avis_container_list_item_note {
  margin-bottom: 2rem;
}
.avis_container_list_item_note img {
  width: 25px;
}
.avis_container_list_item_description {
  margin-bottom: 3rem;
  position: relative;
}
.avis_container_list_item_description p {
  font-size: 13pt;
  line-height: 15pt;
}
.avis_container_list_item_description_container {
  position: relative;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-height: 10em;
  overflow: hidden;
  transition: all 0.5s ease;
}
.avis_container_list_item_description_container.noHidden {
  -webkit-line-clamp: unset;
  max-height: unset;
}
.avis_container_list_item_description .moreAvis {
  cursor: pointer;
  position: absolute;
  bottom: -25px;
  left: 0;
  text-decoration: underline;
}
.avis_container_list_item_description .moreAvis.hidden {
  display: none;
}
.avis_container_list_item_auteurInfo p {
  color: #808080;
  font-size: 13pt;
  font-weight: 700;
  line-height: 15pt;
}
.avis_container .navigationButton {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);
  height: fit-content;
}
.avis_container .navigationButton #avisPrev, .avis_container .navigationButton #avisNext {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .avis {
    padding: 0 15px;
    max-width: 1280px;
    margin: 14rem auto 9rem;
  }
  .avis_title {
    margin-bottom: 3rem;
  }
  .avis_container {
    max-width: 1280px;
    margin: 0 auto 9rem;
  }
  .avis_container_list_item_description {
    margin-bottom: 5rem;
    position: relative;
    cursor: pointer;
  }
}
.blog {
  padding: 15px;
}
.blog_content {
  margin: 5rem 0;
}
.blog_content_left_indiv_title {
  margin-bottom: 3rem;
}
.blog_content_left_indiv_list {
  margin-bottom: 3rem;
}
.blog_content_left_indiv_bottom {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: end;
  margin-top: 2rem;
}
.blog_content_left_indiv_bottom .navigationButton {
  cursor: pointer;
  display: flex;
  gap: 2rem;
  align-items: center;
}
.blog_content_right {
  display: none;
}

@media screen and (min-width: 768px) {
  .blog {
    max-width: 1280px;
    margin: 0 auto;
  }
  .blog_content {
    margin: 5rem 0 10rem;
    display: flex;
    position: relative;
    justify-content: space-between;
  }
  .blog_content_left {
    display: flex;
    flex-direction: column;
    width: 75%;
  }
  .blog_content_right {
    display: block;
    position: sticky;
    top: 10px;
    width: 20%;
    height: fit-content;
  }
  .blog_content_right_list_indiv a {
    color: black;
    text-decoration: none;
  }
  .blog_content_right_list_indiv a h2 {
    text-align: right;
    font-size: 16px;
    font-style: italic;
  }
  .blog_content_right_list_indiv a h2 strong {
    text-align: right;
    font-size: 16px;
    font-style: italic;
  }
}
@media screen and (min-width: 1070px) {
  .blog_content_right {
    display: block;
    position: sticky;
    top: 100px;
    width: 20%;
    height: fit-content;
  }
}
.heroBanner_image {
  height: auto;
  position: relative;
}
.heroBanner_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.heroBanner_image_text {
  padding: 15px;
  margin-top: -5px;
  background-color: black;
}
.heroBanner_image_text h1 {
  color: white;
  font-family: Cairo, sans-serif;
}
.heroBanner_image_text h1 strong {
  font-family: Cairo, sans-serif;
}
.heroBanner_chapeau {
  padding: 15px;
}
.heroBanner_chapeau h2, .heroBanner_chapeau p {
  font-size: 2rem;
  text-align: center;
  color: #869AAB;
  font-family: "Cairo", sans-serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.heroBanner_chapeau h2 strong, .heroBanner_chapeau p strong {
  font-size: 2rem;
}
.heroBanner_chapeau h2 div, .heroBanner_chapeau p div {
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
  color: #869AAB;
  font-family: "Cairo", sans-serif;
}

@media screen and (min-width: 768px) {
  .heroBanner_image {
    height: 70vh;
  }
  .heroBanner_image img {
    height: 100%;
    object-fit: cover;
  }
  .heroBanner_image_text {
    position: absolute;
    bottom: -10%;
    max-width: 650px;
    padding: 50px 115px 50px 115px;
    z-index: 1;
  }
  .heroBanner_chapeau {
    max-width: 900px;
    margin: 0 auto;
  }
  .heroBanner_chapeau h2, .heroBanner_chapeau p {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 24pt;
    line-height: 35pt;
  }
  .heroBanner_chapeau h2 div, .heroBanner_chapeau p div {
    font-size: 24pt;
    line-height: 35pt;
    text-align: center;
    color: #869AAB;
    font-family: "Cairo", sans-serif;
  }
}
.realisations {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 0 30px;
}
.realisations_container_left {
  padding: 30px 15px;
}
.realisations_container_left_title {
  text-align: center;
  margin-top: 3rem;
}
.realisations_container_left_title h2 {
  font-size: 32pt;
  line-height: 32pt;
  font-weight: 700;
}
.realisations_container_left ul {
  overflow: scroll;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  gap: 3rem;
  padding: 30px 0 30px 15px;
  align-items: center;
}
.realisations_container_left ul li {
  flex: 0 0 65%;
  text-align: center;
}
.realisations_container_left ul li p {
  color: #D9D9D9;
  font-size: 16pt;
  line-height: 16pt;
  font-weight: 700;
}
.realisations_container_left ul li.active p {
  color: black;
}
.realisations_container_right {
  position: relative;
}
.realisations_container_right_list_swiper {
  display: none !important;
  transition: 0.3s all ease;
}
.realisations_container_right_list_swiper .sliderTop {
  height: 250px;
  margin-bottom: 1rem;
}
.realisations_container_right_list_swiper .sliderTop img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.realisations_container_right_list_swiper .sliderBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.realisations_container_right_list_swiper .sliderBottom_left {
  font-size: 14pt;
  font-weight: 400;
  line-height: 16pt;
}
.realisations_container_right_list_swiper .sliderBottom_left a {
  color: black;
}
.realisations_container_right_list_swiper.active {
  display: block !important;
}
.realisations_container_right_list .swiper-button-disabled {
  cursor: unset !important;
  opacity: 0;
  transition: 0.3s all ease;
}
.realisations_container_right .sliderBottom {
  display: none;
}
.realisations_container_right .sliderBottom_left {
  text-align: center;
  margin-bottom: 1rem;
}
.realisations_container_right .sliderBottom_left a {
  color: black;
}
.realisations_container_right .sliderBottom.active {
  display: block;
}
.realisations_container_right .sliderBottom.active .navigationButton {
  position: absolute;
  width: 95%;
  display: flex;
  justify-content: space-between;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.realisations_container_right .sliderBottom.active .navigationButton #realPrev0, .realisations_container_right .sliderBottom.active .navigationButton #realPrev1, .realisations_container_right .sliderBottom.active .navigationButton #realPrev2, .realisations_container_right .sliderBottom.active .navigationButton #realNext0, .realisations_container_right .sliderBottom.active .navigationButton #realNext1, .realisations_container_right .sliderBottom.active .navigationButton #realNext2 {
  cursor: pointer;
}
.realisations_container_right .sliderBottom.active .navigationButton #realPrev0:hover, .realisations_container_right .sliderBottom.active .navigationButton #realPrev1:hover, .realisations_container_right .sliderBottom.active .navigationButton #realPrev2:hover, .realisations_container_right .sliderBottom.active .navigationButton #realNext0:hover, .realisations_container_right .sliderBottom.active .navigationButton #realNext1:hover, .realisations_container_right .sliderBottom.active .navigationButton #realNext2:hover {
  animation: bounce 2s ease infinite;
}
.realisations_container_right .sliderBottom .navigationButton {
  display: none;
}

@media screen and (min-width: 768px) {
  .realisations {
    position: relative;
    padding: 0;
  }
  .realisations_container {
    display: flex;
    justify-content: end;
    gap: 5rem;
    margin: 10rem 0;
  }
  .realisations_container_left {
    flex: 0 0 28%;
  }
  .realisations_container_left_title {
    margin-bottom: 5rem;
  }
  .realisations_container_left ul {
    overflow: auto;
    flex-direction: column;
    align-items: start;
  }
  .realisations_container_left ul li {
    cursor: pointer;
    text-align: left;
    font-size: 14pt;
  }
  .realisations_container_left ul li:not(:last-child) {
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 4rem;
  }
  .realisations_container_right {
    flex: 0 0 55%;
    width: 10%;
    height: 580px;
  }
  .realisations_container_right_list {
    height: 100%;
  }
  .realisations_container_right_list_swiper {
    height: 100%;
  }
  .realisations_container_right_list_swiper .sliderTop {
    height: 100%;
    margin-bottom: unset;
  }
  .realisations_container_right_list .sliderBottom {
    display: none;
    flex-direction: row;
    justify-content: end;
    gap: 0;
    align-items: stretch;
    position: relative;
  }
  .realisations_container_right_list .sliderBottom.active .navigationButton {
    position: relative;
    width: 20%;
    display: flex;
    justify-content: space-evenly;
    left: unset;
    top: unset;
    transform: unset;
  }
  .realisations_container_right_list .sliderBottom .navigationButton {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .realisations_container_right_list .sliderBottom_left {
    margin-bottom: unset;
    padding: 0 10px;
    width: 40%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .realisations_container_right_list .sliderBottom_left a {
    color: black;
  }
  .realisations_container_right_list .sliderBottom_right {
    width: 40%;
  }
  .realisations_container_right_list .sliderBottom_right .btn {
    width: 100%;
  }
  .realisations_container_right_list .sliderBottom_right .btn a {
    width: 100%;
    justify-content: center;
  }
  .realisations_container_right_list .sliderBottom.active {
    display: flex;
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(-5px);
  }
}
.planSite_container h2 {
  margin-bottom: 5rem;
  text-align: center;
}
.planSite_container ul {
  margin-left: 2%;
  list-style-type: revert;
}
.planSite_container ul a {
  text-decoration: unset;
  color: black;
  font-size: 16pt;
  line-height: 22pt;
}
.planSite_container ul a:hover {
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .planSite_container {
    max-width: 1280px;
    margin: 9rem auto;
  }
}
.imageBlock {
  padding: 30px 0;
}
.imageBlock img {
  width: 100%;
}
.imageBlock.halfLeftRightPosition {
  display: flex;
  height: 400px;
}
.imageBlock.halfLeftRightPosition picture {
  flex: 0 0 50%;
}
.imageBlock.halfLeftRightPosition picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .imageBlock {
    max-width: 1280px;
    margin: 0 auto;
  }
  .imageBlock.leftHalfPosition {
    width: 100%;
  }
  .imageBlock.rightHalfPosition {
    width: 100%;
    margin: 0 0 0 auto;
  }
}
.articleCard {
  background-color: #F7F7F7;
}
.articleCard_image {
  height: 150px;
}
.articleCard_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.articleCard_content {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.articleCard_content_top {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
}
.articleCard_content_top_etiquette p {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
}
.articleCard_content_top_title h3 {
  font-size: 24px;
  font-weight: 700;
}
.articleCard_content_top_description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-bottom: 2rem;
}
.articleCard_content_btn .btn {
  width: 100%;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .articleCard {
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
  }
  .articleCard_content {
    flex-grow: 1;
    justify-content: space-between;
    min-height: 250px;
  }
  .articleCard_content_top {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .articleCard_content_top_description {
    margin-bottom: 0;
  }
}
.localisation {
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.localisation_iframe {
  border: 1px solid black;
  text-align: center;
}
.localisation_iframe_container {
  padding: 20px 20px;
}
.localisation_iframe_container_subtitle {
  margin: 1rem 0 2rem;
}

@media screen and (min-width: 768px) {
  .localisation {
    max-width: 1280px;
    margin: 9rem auto;
    flex-direction: row;
    align-items: center;
    padding: 0 14px;
    justify-content: space-between;
  }
  .localisation_text {
    max-width: 480px;
  }
  .localisation_iframe {
    flex: 0 0 50%;
  }
  .localisation_iframe_container {
    padding: 40px;
  }
  .localisation_iframe_container_iframe iframe {
    height: 400px;
  }
}
.etapeConstruction {
  padding: 30px 15px;
}
.etapeConstruction_title {
  text-align: center;
  margin-bottom: 3rem;
}
.etapeConstruction_title h2 {
  font-size: 32pt;
  line-height: 32pt;
  font-weight: 700;
}
.etapeConstruction_container {
  margin-bottom: 3rem;
}
.etapeConstruction_container_list {
  padding: 0 30px !important;
}
.etapeConstruction_container_list_item {
  text-align: center;
  border: 1px solid #D9D9D9;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  cursor: auto;
}
.etapeConstruction_container_list_item_title h2 {
  font-weight: 700;
}
.etapeConstruction_container_list_item_description {
  margin-bottom: 3rem;
}
.etapeConstruction_container_list_item_description p {
  font-size: 13pt;
  line-height: 15pt;
}
.etapeConstruction_container .navigationButton {
  z-index: 1;
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);
  height: fit-content;
}
.etapeConstruction_bottom {
  padding: 25px 15px;
  pointer-events: none;
}
.etapeConstruction_bottom_accompagnement, .etapeConstruction_bottom_needHelp {
  text-align: center;
}
.etapeConstruction_bottom_accompagnement {
  margin-bottom: 3rem;
}

@media screen and (min-width: 768px) {
  .etapeConstruction {
    padding: 0 15px;
    max-width: 1280px;
    margin: 14rem auto 9rem;
  }
  .etapeConstruction_title {
    margin-bottom: 5rem;
  }
  .etapeConstruction_container {
    cursor: pointer;
    max-width: 1280px;
    margin: 0 auto;
  }
  .etapeConstruction_container_list .swiper-slide {
    height: auto;
  }
  .etapeConstruction_bottom {
    padding: 70px 30px;
    width: 80%;
    margin: 0 auto;
    position: relative;
  }
}
.takeAppointment {
  position: relative;
  margin: 5% 0 0;
}
.takeAppointment img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.takeAppointment_content {
  padding-top: 3%;
  text-align: center;
  background-color: black;
  padding-bottom: 10%;
}
.takeAppointment_content_title {
  margin-bottom: 1rem;
}
.takeAppointment_content_title h2 {
  color: white;
}
.takeAppointment_content_subtitle {
  margin-bottom: 3rem;
}
.takeAppointment_content_subtitle p {
  color: white;
}

@media screen and (min-width: 768px) {
  .takeAppointment {
    margin: 5% 0 0;
    position: relative;
    height: 500px;
  }
  .takeAppointment_content {
    position: absolute;
    inset: 0;
    width: 50%;
    height: fit-content;
    padding: 60px 100px;
    text-align: left;
    background-color: black;
    max-width: 700px;
  }
  .takeAppointment_content_subtitle {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
  }
  .takeAppointment_content .btn {
    margin: 0;
  }
}
@media screen and (min-width: 1980px) {
  .takeAppointment {
    height: 800px;
  }
}
.faq-container {
  max-width: 1280px;
  margin: 5% auto 0;
  padding: 0 40px;
}
.faq-container .title {
  margin: 5% 0;
  text-align: center;
}
.faq-container .title h2 {
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: black;
}
.faq-container .list {
  padding: 10px 20px;
  margin-bottom: 10%;
  border: 1px solid #B0B0B0;
}
.faq-container .list ul {
  padding: 0;
  list-style-type: none;
}
.faq-container .list ul li {
  font-size: 1rem;
  border-bottom: 1px solid #DADADA;
  margin: 3.2rem 0 1.2rem 0;
  cursor: pointer;
}
.faq-container .list ul li h2 {
  text-align: center;
  position: relative;
  font-size: 19px;
  font-family: "Poppins", sans-serif;
  color: black;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.21;
  padding-right: 20px;
  font-weight: 700;
}
.faq-container .list ul li h2:before {
  transition: all 0.3s;
  background-color: black;
  content: "";
  display: block;
  height: 15px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
}
.faq-container .list ul li h2:after {
  transform: translateY(-50%) rotate(90deg);
  background-color: black;
  content: "";
  display: block;
  height: 15px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 5px;
}
.faq-container .list ul li h2.open:before {
  background-color: black;
  z-index: 99;
  transform: translateY(-50%) rotate(90deg);
  transition: all 0.3s;
}
.faq-container .list ul li span.reponse {
  display: block;
  max-height: 0;
  min-height: 0px;
  font-weight: 200;
  margin-bottom: 1.2rem;
  overflow: hidden;
  transition: all 0.3s;
  padding: 0 0 0 0;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: black;
}
.faq-container .list ul li span.reponse ul {
  list-style-type: none;
}
.faq-container .list ul li span.reponse ul li {
  border-bottom: unset;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
}
.faq-container .list ul li span.reponse ul li strong {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  font-weight: 700;
}
.faq-container .list ul li span.reponse ul li:before {
  content: "•"; /* Insert content that looks like bullets */
  padding-right: 8px;
  color: black; /* Or a color you prefer */
}
.faq-container .list ul li span.reponse p u {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  text-decoration: underline;
}
.faq-container .list ul li span.reponse p a {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  color: black;
}
.faq-container .list ul li span.reponse p a u {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  text-decoration: underline;
}
.faq-container .list ul li span.reponse p a strong {
  font-weight: 700;
}
.faq-container .list ul li span.reponse a {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  color: black;
}
.faq-container .list ul li span.reponse a u {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  text-decoration: underline;
}
.faq-container .list ul li span.reponse a strong {
  font-weight: 700;
}
.faq-container .list ul li h2.open + span.reponse {
  max-height: 1000px;
  transition: all 0.3s;
  padding: 1.2rem 0 0 0;
}
.faq-container .list ul li:last-child {
  border: none;
}
.faq-container .list ul p {
  text-align: center;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .faq-container {
    width: 100%;
    margin: unset;
    padding: 30px 15px;
  }
}
.prestation {
  padding: 30px 0;
}
.prestation_image {
  width: 100%;
}
.prestation_image img {
  width: 100%;
}
.prestation_content {
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.prestation_content_btn {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
}
.prestation_content_btn .btn {
  width: 100%;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .prestation {
    max-width: 1280px;
    margin: 14rem auto 9rem;
    padding: 0;
  }
  .prestation_content_btn {
    flex-direction: row;
    width: 80%;
    margin: 0 auto;
  }
  .prestation_content_btn .btn {
    width: 30%;
    margin: 0 0 0 auto;
  }
  .prestation_content_btn .btn:nth-child(2) {
    margin: 0 auto 0 0;
  }
}
.textImage-single {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 30px 0;
}
.textImage-single_text_content ul li, .textImage-single_text_content ol li {
  color: #000;
  font-size: 14pt;
  line-height: 22pt;
}
.textImage-single_text_content ul li strong, .textImage-single_text_content ol li strong {
  font-weight: 700;
  color: #000;
  font-size: 14pt;
  line-height: 22pt;
}
.textImage-single.leftPosition {
  flex-direction: column-reverse;
}
.textImage-single_text {
  padding: 15px;
}
.textImage-single_image img {
  width: 100%;
  display: flex;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .textImage-single {
    flex-direction: row;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    justify-content: space-between;
    padding: 9rem 0 0;
  }
  .textImage-single h2 {
    font-weight: 700;
    color: #869aab;
  }
  .textImage-single p {
    font-size: 14pt;
    line-height: 22pt;
  }
  .textImage-single p i {
    font-size: 14pt;
    line-height: 22pt;
  }
  .textImage-single.leftPosition {
    flex-direction: row-reverse;
  }
  .textImage-single_image {
    flex: 0 0 50%;
  }
  .textImage-single_text {
    max-width: 600px;
  }
}
.text, .textEncadre {
  max-width: 1280px;
  margin: 3rem auto;
  padding: 40px 15px;
}
.text h2, .textEncadre h2 {
  margin-bottom: 3rem;
}
.text p, .text a, .text li, .textEncadre p, .textEncadre a, .textEncadre li {
  color: #000;
  font-size: 14pt;
  line-height: 22pt;
}
.text p strong, .text p a, .text a strong, .text a a, .text li strong, .text li a, .textEncadre p strong, .textEncadre p a, .textEncadre a strong, .textEncadre a a, .textEncadre li strong, .textEncadre li a {
  font-size: 14pt;
  line-height: 22pt;
}
.text a, .textEncadre a {
  text-decoration: underline;
}
.text a:hover, .textEncadre a:hover {
  color: #869aab;
}

.textEncadre_container {
  padding: 30px 25px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 5px 5px #d9d9d9;
}
.textEncadre_container.half {
  width: 50%;
  margin: 0 auto;
}
.textEncadre_container.fitContent {
  width: fit-content;
}
.textEncadre_container.white {
  background-color: white;
}
.textEncadre_container.black {
  background-color: black;
}
.textEncadre_container.black h2, .textEncadre_container.black p, .textEncadre_container.black h3, .textEncadre_container.black h4, .textEncadre_container.black h5, .textEncadre_container.black li, .textEncadre_container.black a {
  color: white;
}
.textEncadre_container.black a:hover::after {
  content: unset;
}
.textEncadre_container.grey {
  background-color: #F0F0F0;
}
.textEncadre_container.grey a:hover::after {
  content: unset;
}
.textEncadre_container.center {
  margin: 0 auto;
}
.textEncadre_container.left {
  margin: 0 auto 0 0;
}
.textEncadre_container.right {
  margin: 0 0 0 auto;
}

@media screen and (min-width: 768px) {
  .text, .textEncadre {
    margin: 0 auto;
    padding: 9rem 15px 0;
  }
}
.expertsArtisans {
  padding: 40px 0;
}
.expertsArtisans_container_left_title {
  padding: 0 15px;
  margin: 0 0 5%;
}
.expertsArtisans_container_left_title h2 {
  color: #869AAB;
}
.expertsArtisans_container_left_nbArtisans {
  padding: 40px 20px;
  background-color: #F0F0F0;
  text-align: center;
}
.expertsArtisans_container_left_nbArtisans p {
  line-height: normal;
}
.expertsArtisans_container_left_nbArtisans p strong {
  font-size: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6.9rem;
}
.expertsArtisans_container_left_nbArtisans p i strong {
  font-size: 1.6rem;
  height: auto;
}
.expertsArtisans_container_left_nbArtisans .swiperNavigation {
  display: none;
}
.expertsArtisans_container_right_list_swiper img {
  width: 100%;
}
.expertsArtisans_container_right_list_swiper .navigationButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-top: 5%;
}
.expertsArtisans_container_right_list_swiper .navigationButton #expertsArtisansPrev, .expertsArtisans_container_right_list_swiper .navigationButton #expertsArtisansNext {
  opacity: 1;
  transition: 0.3s all ease;
}
.expertsArtisans_container_right_list_swiper .navigationButton #expertsArtisansPrev.swiper-button-disabled, .expertsArtisans_container_right_list_swiper .navigationButton #expertsArtisansNext.swiper-button-disabled {
  opacity: 0;
  transition: 0.3s all ease;
}
.expertsArtisans_description {
  padding: 0 15px;
  margin: 5% 0;
}

@media screen and (min-width: 768px) {
  .expertsArtisans {
    max-width: 1280px;
    margin: 9rem auto 15rem;
  }
  .expertsArtisans_container {
    display: flex;
  }
  .expertsArtisans_container_left {
    flex: 0 0 50%;
  }
  .expertsArtisans_container_left_title {
    padding-left: 35px;
  }
  .expertsArtisans_container_left_nbArtisans {
    height: 99%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .expertsArtisans_container_left_nbArtisans .swiperNavigation {
    display: flex;
    justify-content: end;
    gap: 3rem;
  }
  .expertsArtisans_container_left_nbArtisans .swiperNavigation #expertsArtisansPrev, .expertsArtisans_container_left_nbArtisans .swiperNavigation #expertsArtisansNext {
    transition: 0.3s all ease;
    cursor: pointer;
  }
  .expertsArtisans_container_left_nbArtisans .swiperNavigation #expertsArtisansPrev.swiper-button-disabled, .expertsArtisans_container_left_nbArtisans .swiperNavigation #expertsArtisansNext.swiper-button-disabled {
    opacity: 0;
    transition: 0.3s all ease;
  }
  .expertsArtisans_container_left_nbArtisans:before {
    box-shadow: 0 40vw 0 50vw #F0F0F0;
    -webkit-clip-path: inset(0 -50vw 0 -40vw);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    pointer-events: none;
  }
  .expertsArtisans_container_right {
    flex: 0 0 50%;
    width: 50%;
    position: relative;
  }
  .expertsArtisans_container_right_list_swiper .navigationButton {
    position: absolute;
    left: -5%;
  }
  .expertsArtisans_description {
    position: relative;
  }
  .expertsArtisans_description p {
    text-align: left;
    font-size: 14pt;
    line-height: 22pt;
  }
}
.joinUs {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 40px 15px;
}
.joinUs_description h2 {
  color: #889AAB;
}
.joinUs_btn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.joinUs_btn .btn {
  width: 100%;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .joinUs {
    flex-direction: row;
    padding: 0 14px;
    max-width: 1280px;
    margin: 9rem auto;
    justify-content: center;
    gap: 9rem;
  }
  .joinUs_description {
    width: 390px;
  }
  .joinUs_btn {
    flex: 0 0 30%;
    justify-content: center;
  }
}
.savoirFaire {
  padding: 30px 0;
}
.savoirFaire_header {
  padding: 0 14px;
}
.savoirFaire_header h2 {
  color: #889AAB;
}
.savoirFaire_container_list {
  overflow: scroll;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  gap: 3rem;
  padding: 30px 15px;
  align-items: center;
}
.savoirFaire_container_list li {
  flex: 0 0 50%;
  text-align: center;
}
.savoirFaire_container_list li.active p {
  color: black;
  font-weight: 700;
}
.savoirFaire_container_list li p {
  cursor: pointer;
  color: #D9D9D9;
}
.savoirFaire_container_text {
  position: relative;
  background-color: #F0F0F0;
  padding: 3rem 2rem 3rem 4rem;
}
.savoirFaire_container_text_indiv {
  display: none;
}
.savoirFaire_container_text_indiv ul li {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
}
.savoirFaire_container_text_indiv.active {
  display: block;
}
.savoirFaire_container_text:before {
  box-shadow: 0 40vw 0 40vw #F0F0F0;
  -webkit-clip-path: inset(0 -40vw 0 0);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.savoirFaire_conclusion {
  text-align: center;
  max-width: 900px;
  margin: 3rem auto 0;
}

@media screen and (min-width: 768px) {
  .savoirFaire {
    max-width: 1280px;
    margin: 0rem auto;
  }
  .savoirFaire_header {
    margin: 0 auto;
    text-align: center;
    max-width: 730px;
  }
  .savoirFaire_header p {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  .savoirFaire_container {
    display: flex;
    gap: 5rem;
    margin: 5rem 0;
    justify-content: center;
  }
  .savoirFaire_container_header {
    text-align: right;
  }
  .savoirFaire_container_list {
    flex: 0 0 28%;
    flex-direction: column;
    overflow: unset;
    gap: 3rem;
    justify-content: center;
    align-items: start;
  }
  .savoirFaire_container_list li {
    display: flex;
    align-items: center;
    flex: unset;
    width: 100%;
  }
  .savoirFaire_container_list li p {
    font-size: 24px;
    line-height: 30px;
  }
  .savoirFaire_container_list li:not(:last-child) {
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 4rem;
  }
  .savoirFaire_container_text {
    flex: 0 0 55%;
    padding: 10rem 0rem 10rem 5rem;
  }
  .savoirFaire_container_text_indiv p {
    font-size: 14pt;
    line-height: 22pt;
  }
  .savoirFaire_container_text_indiv ul li {
    font-size: 14pt;
    line-height: 22pt;
    margin-bottom: 0.5rem;
  }
  .savoirFaire_conclusion {
    margin: 0 auto;
  }
}
.nosMateriaux {
  padding: 30px 0;
}
.nosMateriaux_image {
  display: none;
}
.nosMateriaux_image img {
  width: 100%;
  display: flex;
}
.nosMateriaux_container {
  background-color: #889AAB;
  padding: 5rem 14px;
}
.nosMateriaux_container_header {
  margin-bottom: 3rem;
}
.nosMateriaux_container_header_title {
  margin-bottom: 2rem;
  text-align: center;
}
.nosMateriaux_container_header_subtitle {
  text-align: center;
}
.nosMateriaux_container_list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.nosMateriaux_container_list_indiv {
  background-color: white;
  position: relative;
}
.nosMateriaux_container_list_indiv_image {
  height: 250px;
}
.nosMateriaux_container_list_indiv_image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.nosMateriaux_container_list_indiv_content {
  height: 80%;
  padding: 20px 20px 20px 40px;
  text-align: center;
}
.nosMateriaux_container_list_indiv_content h2, .nosMateriaux_container_list_indiv_content h3 {
  margin-bottom: 3rem;
}
.nosMateriaux_container_list_indiv_content ul {
  list-style-type: none;
}
.nosMateriaux_container_list_indiv_content ul li {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  position: relative;
}
.nosMateriaux_container_list_indiv_content ul li:not(:nth-last-child(1)) {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.nosMateriaux_container_list_indiv_content ul li:not(:nth-last-child(1)):after {
  content: "";
  width: 30%;
  height: 1px;
  position: absolute;
  color: #869AAB;
  background-color: #869AAB;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 768px) {
  .nosMateriaux {
    max-width: 1280px;
    margin: 0 auto;
    padding: 30px 0 0;
  }
  .nosMateriaux_image {
    width: 50%;
    margin: 0 0 0 auto;
    position: relative;
    z-index: 2;
  }
  .nosMateriaux_container {
    position: relative;
    padding: 10rem;
  }
  .nosMateriaux_container_header_title {
    margin-bottom: 5rem;
  }
  .nosMateriaux_container_header_subtitle {
    width: 80%;
    margin: 0 auto;
  }
  .nosMateriaux_container_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
  .nosMateriaux_container_list_indiv_image {
    height: 200px;
  }
  .nosMateriaux_container_list_indiv_content {
    text-align: center;
    height: 70%;
    padding: 40px 35px 40px 35px;
  }
  .nosMateriaux_container_list_indiv_content h2, .nosMateriaux_container_list_indiv_content h3 {
    margin-bottom: 3rem;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nosMateriaux_container::before {
    pointer-events: none;
    box-shadow: 0 40vw 0 40vw #889AAB;
    -webkit-clip-path: inset(0 -40vw 0 -40vw);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}
.article_title {
  text-align: center;
  margin-top: 3rem;
  padding: 0 15px;
}
.article_container {
  padding: 0 15px;
  margin-top: 3rem;
}
.article_container_subtitle {
  padding: 0 15px;
}
.article_container_subtitle p.date {
  margin-bottom: 1rem;
}
.article .text {
  text-align: left;
}
.article_more {
  padding: 0 15px;
  margin-top: 3rem;
  margin-bottom: 5rem;
}
.article_more_title {
  margin-bottom: 3rem;
}
.article_more_list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

@media screen and (min-width: 768px) {
  .article {
    max-width: 1280px;
    margin: 0 auto;
  }
  .article_title {
    margin: 3rem auto 10rem;
  }
  .article_container {
    max-width: 850px;
    margin: 0 auto;
  }
  .article_container_subtitle {
    margin-bottom: 5rem;
    padding: 0;
  }
  .article .text {
    margin: 0;
  }
  .article_more_list {
    flex-direction: row;
    justify-content: space-between;
  }
}
.auteur {
  display: flex;
  align-items: end;
  gap: 1.5rem;
  padding: 40px 15px;
}
.auteur_image {
  height: 100px;
}
.auteur_image img {
  height: 100%;
}
.auteur_info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.auteur_info p {
  font-style: italic;
}

@media screen and (min-width: 768px) {
  .auteur {
    padding: 40px 0;
  }
}
.breadcrumb {
  padding: 0 15px;
  margin: 3rem 0 5rem;
  gap: 0.5rem;
  display: none;
}
.breadcrumb a {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: italic;
  color: black;
}
.breadcrumb a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: black;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}
.breadcrumb a:hover:after {
  transform: scaleX(100%);
}
.breadcrumb p {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: italic;
  color: black;
}

@media screen and (min-width: 768px) {
  .breadcrumb {
    display: flex;
    max-width: 1280px;
    margin: 10rem auto 2rem;
    padding: 0;
  }
}
.listDeroulante {
  max-width: 1280px;
  margin: 5% auto 0;
  padding: 0 40px;
}
.listDeroulante .title {
  margin: 5% 0;
  text-align: center;
}
.listDeroulante .title h2 {
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: black;
}
.listDeroulante .list {
  padding: 10px 20px;
  margin-bottom: 10%;
}
.listDeroulante .list ul {
  padding: 0;
  list-style-type: none;
}
.listDeroulante .list ul li {
  font-size: 1rem;
  margin: 3.2rem 0 1.2rem 0;
  cursor: pointer;
}
.listDeroulante .list ul li h2 {
  position: relative;
  font-size: 19px;
  font-family: "Poppins", sans-serif;
  color: black;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.21;
  padding-right: 20px;
  font-weight: 700;
}
.listDeroulante .list ul li span.reponse {
  display: block;
  max-height: 0;
  min-height: 0px;
  font-weight: 200;
  margin-bottom: 1.2rem;
  overflow: hidden;
  transition: all 0.3s;
  padding: 0 0 0 0;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: black;
}
.listDeroulante .list ul li span.reponse ul {
  list-style-type: none;
}
.listDeroulante .list ul li span.reponse ul li {
  border-bottom: unset;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
}
.listDeroulante .list ul li span.reponse ul li strong {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  font-weight: 700;
}
.listDeroulante .list ul li span.reponse p u {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  text-decoration: underline;
}
.listDeroulante .list ul li span.reponse p a {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  color: black;
}
.listDeroulante .list ul li span.reponse p a u {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  text-decoration: underline;
}
.listDeroulante .list ul li span.reponse p a strong {
  font-weight: 700;
}
.listDeroulante .list ul li span.reponse a {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  color: black;
}
.listDeroulante .list ul li span.reponse a u {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  text-decoration: unset;
  text-decoration: underline;
}
.listDeroulante .list ul li span.reponse a strong {
  font-weight: 700;
}
.listDeroulante .list ul li h2.open + span.reponse {
  max-height: 1000px;
  transition: all 0.3s;
  padding: 1.2rem 0 0 0;
}
.listDeroulante .list ul li:last-child {
  border: none;
}
.listDeroulante .list ul p {
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .faq-container {
    width: 100%;
    margin: unset;
    padding: 30px 15px;
  }
}
.realisationPage-next-previous {
  max-width: 1280px;
  margin: 5rem auto;
  display: flex;
  justify-content: space-between;
  padding: 0 14px;
}
.realisationPage-next-previous .next a, .realisationPage-next-previous .previous a {
  display: flex;
  gap: 2rem;
}
.realisationPage-next-previous .next a span, .realisationPage-next-previous .previous a span {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: "Inter", sans-serif;
  color: black;
}
.realisationPage-next-previous .next a:after, .realisationPage-next-previous .previous a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: black;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}
.realisationPage-next-previous .next a:hover:after, .realisationPage-next-previous .previous a:hover:after {
  transform: scaleX(100%);
}