.homepage{
  &_heroBanner{
    position: relative;
    overflow: hidden;
    &_swiper{
      overflow: unset !important;
      &_list{
        height: auto;
        &_item{
          height: auto !important;
          &_slide{
            height: auto;
          }
          img{
            width: 100%;
            object-fit: cover;
            height: 100%;
          }
        }
      }
      .navigationButton{
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        pointer-events: none;
        #hpHeroBannerPrev, #hpHeroBannerNext{
          pointer-events: auto;
          cursor: pointer;
        }
      }
    }
    &_title{
      text-align: center;
      padding: 15px;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      &_subTitle{
        margin: 3rem;
        text-align: left;
        display: none;
        h1{
          font-size: 14pt;
          line-height: 14pt;
        }
      }
      &_header{
        display: flex;
        flex-direction: column;
        gap: 1rem;

        padding: 15px;
        margin-top: -5px;
        background-color: black;
        h2{
          color: white;
          font-size: 3rem;
          font-weight: 700;
        }
        h1{
          color: white;
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
      &_chapeau{
        p{
          font-size: 2rem;
          text-align: center;
          color: $grey;
          font-family: 'Cairo', sans-serif;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          div{
            font-size: 2rem;
            line-height: 2rem;
            text-align: center;
            color: #869AAB;
            font-family: "Cairo", sans-serif;
          }
        }
      }
    }
  }
  .zoneIntervention{
    padding: 30px 0 0;
    &_container{
      padding: 0 15px;
      text-align: center;
      margin-bottom: 3rem;
      &_title{
        margin-bottom: 3rem;
        h2{
          font-size: 32pt;
          line-height: 32pt;
          font-weight: 700;
        }
      }
      &_sousTitre{
        p{
          font-size: 13pt;
          line-height: 15pt;
        }
      }
    }
    &_iframe{
      height: 300px;
      iframe{
        width: 100%;
        height: 100%;
      }
    }
  }
  .realisations{
    max-width: 1280px;
    margin: 0 auto;
    &_container{
      &_left{
        padding: 30px 15px;
        &_title{
          text-align: center;
          margin-top: 3rem;
          h2{
            font-size: 32pt;
            line-height: 32pt;
            font-weight: 700;
          }
        }
        ul{
          overflow: scroll;
          list-style-type: none;
          display: flex;
          flex-wrap: nowrap;
          gap: 3rem;
          padding: 30px 0 30px 15px;
          align-items: center;
          li{
            flex: 0 0 65%;
            text-align: center;
            p{
              color: #D9D9D9;
              font-size: 16pt;
              line-height: 16pt;
              font-weight: 700;
              transition: .3s all ease;
              &:hover{
                color: black;
                transition: .3s all ease;
              }
            }
            &.active{
              p{
                color: black;
              }
            }
          }
        }
      }
      &_right{
        &_list{
          &_swiper{
            display: none;
            transition: .3s all ease;
            .sliderTop{
              height: 250px;
              margin-bottom: 1rem;
              img{
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
            }
            .sliderBottom{
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 1rem;
              &_left{
                font-size: 14pt;
                font-weight: 400;
                line-height: 16pt;
                a{
                  color: black;
                }
              }
            }
            &.active{
              display: block;
            }
          }

          .swiper-button-disabled{
            opacity: 0;
            transition: .3s all ease;
          }
        }
        .sliderBottom{
          display: none;
          &_left{
            text-align: center;
            margin-bottom: 1rem;
          }
          &.active{
            display: block;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .homepage{
    &_heroBanner{
      &_swiper{
        &_list{
          height: 70vh;
          &_item{
            height: 70vh !important;
          }
        }
      }
      &_title{
        max-width: 1280px;
        margin: 0 auto;
        &.chapeauHomepage{
          margin-top: 5rem;
        }
        &_subTitle{
          display: block;
        }
        &_header{
          text-align: left;
          margin-bottom: 10rem;

          position: absolute;
          bottom: 14%;
          max-width: 650px;
          padding: 50px 115px 50px 115px;
          z-index: 1;

          h2{
            font-size: 3.2rem;
            line-height: 3.5rem;
            max-width: 520px;
            margin-bottom: 2rem;
          }
          h1{
            font-size: 14pt;
            line-height: 14pt;
          }
        }
        &_chapeau{
          max-width: 900px;
          margin: 0 auto;
          p{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            div{
              font-size: 24pt;
              line-height: 35pt;
              text-align: center;
              color: #869AAB;
              font-family: "Cairo", sans-serif;
              strong{
                font-size: 24pt;
                line-height: 35pt;
                text-align: center;
                font-family: "Cairo", sans-serif;
              }
            }
            font-size: 24pt;
            line-height: 35pt;
          }
        }
      }
    }
    .zoneIntervention{
      max-width: 1280px;
      margin: 5rem auto 10rem;
      &_container{
        max-width: 1000px;
        margin: 3rem auto;
        &_sousTitre{
          p{
            //line-height: 28pt;
          }
        }
      }
      &_iframe{
        height: 500px;
        width: 60%;
        margin: 0 auto;
      }
    }
    .realisations{
      position: relative;
      &_container{
        display: flex;
        justify-content: end;
        gap: 5rem;
        &_left{
          flex: 0 0 28%;
          &_title{
            margin-bottom: 5rem;
          }
          ul{
            overflow: auto;
            flex-direction: column;
            align-items: start;
            li{
              cursor: pointer;
              text-align: left;
              font-size: 14pt;
              &:not(:last-child){
                border-bottom: 1px solid #D9D9D9;
                padding-bottom: 4rem;
              }
            }
          }
        }
        &_right{
          height: 580px;
          flex: 0 0 55%;
          width: 10%;
          &_list{
            height: 100%;
            &_swiper{
              height: 100%;
              .sliderTop{
                height: 100%;
                margin-bottom: unset;
                img{
                  height: 100%;
                  object-fit: cover;
                  width: 100%;
                }
              }
            }
            .sliderBottom{
              display: none;
              flex-direction: row;
              justify-content: end;
              gap: 0;
              align-items: stretch;
              position: relative;
              .navigationButton{
                cursor: pointer;
                width: 20%;
                display: flex;
                align-items: center;
                justify-content: space-evenly;

                #realPrev0, #realNext0{
                  &:hover{
                    animation: bounce 2s ease infinite;
                  }
                }
              }
              &_left{
                margin-bottom: unset;
                padding: 0 10px;
                width: 40%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                //border: 1px solid black;

                a{
                  color: var(--black);
                }
              }
              &_right{
                width: 40%;
                .btn{
                  width: 100%;
                  a{
                    width: 100%;
                    justify-content: center;
                  }
                }
              }
              &.active{
                display: flex;
              }
            }
          }
        }
      }
      //&:before{
      //  content: '';
      //  position: absolute;
      //  width: 35%;
      //  height: 20%;
      //  top: -50px;
      //  right: 0;
      //  background-color: black;
      //}
    }
  }
}