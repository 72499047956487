.contenuSimple{
  position: relative;
  text-align: center;
  overflow: hidden;
  .pattern{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 90%;
    object-fit: contain;
    transform: translateY(-10%);
    filter: opacity(0.7);
  }
  &_container{
    position: relative;
    z-index: 1;
    padding: 30px 15px;
    &_description{
      h2{
        font-size: 24pt;
        line-height: 25pt;
        font-weight: 700;
        margin-bottom: 3rem;
        font-family: Inter, sans-serif;
      }
      p{
        font-size: 2rem;
        line-height: 2rem;
      }
      margin-bottom: 5rem;
    }
    .btn{
      margin-top: 3rem;
    }
  }
}

@media screen  and (min-width: 768px){
  .contenuSimple{
    .pattern{
      transform: translateY(-30%);
    }
    &_container{
      max-width: 1280px;
      margin: 0 auto;
      padding: 9rem 15px 12rem;
      &_description{
        max-width: 1000px;
        margin: 0 auto;
        p,h2{
          font-size: 21pt;
          line-height: 21pt;
        }
      }
    }
  }
}