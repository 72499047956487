.localisation{
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  &_iframe{
    border: 1px solid black;
    text-align: center;
    &_container{
      padding: 20px 20px;
      &_subtitle{
        margin: 1rem 0 2rem;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .localisation{
    max-width: 1280px;
    margin: 9rem auto;
    flex-direction: row;
    align-items: center;
    padding: 0 14px;
    justify-content: space-between;
    &_text{
      max-width: 480px;
    }
    &_iframe{
      flex: 0 0 50%;
      &_container{
        padding: 40px;
        &_iframe{
          iframe{
            height: 400px;
          }
        }
      }
    }
  }

}