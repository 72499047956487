.auteur{
  display: flex;
  align-items: end;
  gap: 1.5rem;
  padding: 40px 15px;
  &_image{
    height: 100px;
    img{
      height: 100%;
    }
  }
  &_info{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    p{
      font-style: italic;
    }
  }
}

@media screen and (min-width: 768px){
  .auteur{
    padding: 40px 0;
  }
}